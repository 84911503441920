import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { PipesModule } from '@app/shared/pipes';

import { MessageBoxComponent } from './message-box.component';
import { MessageBoxIconPipe } from './message-box-icon.pipe';

@NgModule({
  declarations: [MessageBoxComponent, MessageBoxIconPipe],
  exports: [MessageBoxComponent],
  imports: [CommonModule, InlineSVGModule, PipesModule]
})
export class MessageBoxModule {}
