import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export const sidenavCollapsedKey = 'flSidenavCollapsed';

export interface AppLayoutState {
  mobileMenuActive: boolean;
  sidenavCollapsed?: boolean;
}

function createInitialState(): AppLayoutState {
  return {
    mobileMenuActive: false,
    sidenavCollapsed: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app-layout', resettable: true })
export class AppLayoutStore extends Store<AppLayoutState> {
  constructor() {
    super(createInitialState());
  }
}
