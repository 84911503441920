<h1>Edit Content</h1>

<mat-tab-group [@.disabled]="true">
  <mat-tab label="Basic Editor">
    <fl-froala-editor
      id="froala-editor"
      [froalaEditor]="froalaHelper.content"
      [(froalaModel)]="content"
    ></fl-froala-editor>
  </mat-tab>
  <mat-tab label="Advanced Editor">
    <ace [(value)]="content" [mode]="'html'" [theme]="'tomorrow'"></ace>
  </mat-tab>
</mat-tab-group>

<div *ngIf="variablesMissed.length > 0">
  These variables don't exist:
  <button
    mat-raised-button
    color="primary-light"
    class="missing-var"
    *ngFor="let variable of variablesMissed"
    (click)="openCreateVariableModal(variable)"
  >
    Create {{ variable }}
  </button>
</div>

<div class="mat-footer">
  <button mat-button (click)="onNoClick()">Close</button>
  <button mat-raised-button color="primary" (click)="validateAndClose()" cdkFocusInitial>Save</button>
</div>
