import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';

import { AuthorizationLayoutModule } from '@app/layout/authorization-layout';
import { MessageBoxModule } from '@component/message-box';
import { NoneModule } from '@pipe/none';

import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';

export let imports = [
  CommonModule,
  FormsModule,
  InlineSVGModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule
];

@NgModule({
  declarations: [LoginComponent],
  imports: [...imports, AuthorizationLayoutModule, LoginRoutingModule, MessageBoxModule, NoneModule]
})
export class LoginModule {}
