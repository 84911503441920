import { Pipe, PipeTransform } from '@angular/core';
import { BadgeColor } from '@component/badge/badge.component';

@Pipe({
  name: 'asBadgeColorValue'
})
export class AsBadgeColorValuePipe implements PipeTransform {
  transform(row: any, getBadgeColor: any): BadgeColor {
    let badgeColor: BadgeColor = getBadgeColor(row);
    if (Object.values(BadgeColor).includes(badgeColor)) return badgeColor;
  }
}
