import { environment } from '@env/environment';

export abstract class ZendeskConfigAbstract {
  lazyLoad: boolean;
  timeOut: number;
  injectionTag: 'head' | 'body' | 'script';
  abstract accountUrl: string;
  abstract callback(zE: any): any;
}

export class ZendeskConfig extends ZendeskConfigAbstract {
  accountUrl = environment.ZENDESK_ACCOUNT_URL;
  callback(): void {}
}
