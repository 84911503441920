import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { Form } from '@state/form';

import { Task, TaskVehicle } from './task.model';

export interface TaskState {
  form?: Form;
  formValues: Record<string, unknown>;
  task?: Task;
  vehicle?: TaskVehicle;
}

export function createInitialState(): TaskState {
  return {
    form: undefined,
    formValues: {},
    task: undefined,
    vehicle: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'task', resettable: true })
export class TaskStore extends Store<TaskState> {
  constructor() {
    super(createInitialState());
  }
}
