import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-labeled-horizontal-separator',
  styleUrls: ['./labeled-horizontal-separator.component.scss'],
  templateUrl: './labeled-horizontal-separator.component.html'
})
export class LabeledHorizontalSeparatorComponent {
  @Input() label: string;
}
