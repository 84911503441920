import { Pipe, PipeTransform } from '@angular/core';

import { IconMetaColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asIconMetaColumn'
})
export class AsIconMetaColumnPipe implements PipeTransform {
  transform(column: ColumnLike): IconMetaColumn | false {
    if (column.type === ColumnType.IconMeta) return column as IconMetaColumn;
    return false;
  }
}
