import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable, tap } from 'rxjs';

import { environment } from '@env/environment';

import { Form, MemberOnline } from './form.model';
import { FormRepository } from './form.repository';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private formRepository: FormRepository,
    private http: HttpClient
  ) {}

  addMember({ id, info }: { id: ID, info: MemberOnline }): void {
    this.formRepository.addMemberOnline(id, info);
  }

  getAndStorePreviewForm$(id: ID): Observable<Form> {
    return this.http.get<Form>(`${environment.apiUrl}/v1/forms/${id}`).pipe(
      tap({
        next: (form) => {
          this.formRepository.setPreviewForm(form);
        }
      })
    );
  }

  removeMember(id: ID): void {
    this.formRepository.removeMemberOnline(id);
  }

  setform(form: Form): void {
    this.formRepository.setForm(form);
  }

  setMembersOnline(membersOnline: ReadonlyMap<string, MemberOnline>): void {
    this.formRepository.setMembersOnline(membersOnline);
  }
}
