import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'routeMatch' })
export class RouteMatchPipe implements PipeTransform {
  constructor() {}
  transform(value: string, checkAgainst: string): boolean {
    if (!value) return;
    let regex: RegExp;

    if (checkAgainst === 'entities') {
      regex = new RegExp(`^(\/p\/)?([a-zA-Z0-9-]+)?(\/e\/)?([a-zA-Z0-9-]+)?\/entit(ies|y)`);
    } else {
      regex = new RegExp(`^(\/p\/)?([a-zA-Z0-9-]+)?(\/e\/)?([a-zA-Z0-9-]+)?\/${checkAgainst}`);
    }
    return regex.test(value);
  }
}
