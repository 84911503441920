import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseService } from './base.service';
import { FormService } from './form.service';

@Injectable()
export class VariableService extends BaseService {
  BASE_URL: string;
  GLOBAL_ORG_ID = '00000000-0000-0000-0000-000000000000';

  constructor(private formService: FormService, public http: HttpClient) {
    super();
  }

  createVariable(variable: any, callbacks?: any): Subscription {
    const { BASE_URL, GLOBAL_ORG_ID: organization_id } = this;

    return this.http
      .post(`${BASE_URL}/v1/variables`, {
        organization_id,
        variable
      })
      .subscribe({
        error: (error) => {
          this.applyCallback('error', callbacks, error);
        },
        next: (data) => {
          this.applyCallback('success', callbacks, data);
        }
      });
  }
}
