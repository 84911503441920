import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

import { DocumentVersion } from './document-version.model';

type IDLike = ID | string;

@Injectable({ providedIn: 'root' })
export class DocumentVersionsService {
  constructor(private http: HttpClient) {}

  getDocumentVersions$(document_id: IDLike): Observable<DocumentVersion[]> {
    let url = `${environment.apiUrl}/v1/documents/${document_id}/document_versions`;
    return this.http.get<DocumentVersion[]>(url);
  }
}
