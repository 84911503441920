import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';

import { AuthorizationLayoutModule } from '@app/layout/authorization-layout/authorization-layout.module';
import { FlowMaterialModule } from '@app/flow-material.module';
import { NoneModule } from '@pipe/none';

import { ForgotPasswordComponent } from './forgot-password.component';
import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    AuthorizationLayoutModule,
    CommonModule,
    ForgotPasswordRoutingModule,
    InlineSVGModule,
    FlowMaterialModule,
    MatSnackBarModule,
    NoneModule
  ]
})
export class ForgotPasswordModule {}
