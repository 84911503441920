export function applyValues({ data, keys, obj }: { data: object; keys: string[]; obj: object }): void {
  for (let key of keys) {
    obj[key] = data[key];
  }
}

export function hasAnyNotBlank(obj: any, keys: string[]): boolean {
  return keys.some((key) => obj[key] != null); // only null and undefined
}

export function isEmpty(obj: object): boolean {
  return obj.constructor === Object && !Object.keys(obj).length;
}

export function removeEmptyValues<T>(obj: T): T {
  let filteredObjectEntries = Object.entries(obj).filter(([, v]) => v != null);
  return Object.fromEntries(filteredObjectEntries) as T;
}
