import { Pipe, PipeTransform } from '@angular/core';

import { ValueTagsColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asValueTagsColumn'
})
export class AsValueTagsColumnPipe implements PipeTransform {
  transform(column: ColumnLike): ValueTagsColumn | false {
    if (column.type === ColumnType.ValueTags) return column as ValueTagsColumn;
    return false;
  }
}
