import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { getPathInfo, getQueryParamsObject } from '@helpers';

import { Invitation, InvitationStore } from './invitation.store';

@Injectable({ providedIn: 'root' })
export class InvitationService {
  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private invitationStore: InvitationStore,
    private router: Router
  ) {}

  acceptInvitation$(invitation: Invitation): Observable<any> {
    return this.http.post(`${environment.apiUrl}/invitations/${invitation.token}/accept`, invitation);
  }

  peek$(token: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/invitations/${token}/peek`).pipe(
      tap({
        next: (invitation: Invitation) => {
          this.invitationStore.update({ invitation });
        }
      })
    );
  }

  redirectToFinalDestination(invitation: Invitation): void {
    let { browser, isCurrentHost, url } = getPathInfo(invitation.redirect_url);

    if (isCurrentHost) {
      let queryParams = {};
      if (url.search) queryParams = getQueryParamsObject(url.search);
      void this.router.navigate([url.pathname], { queryParams });
    } else {
      browser.location = url;
    }
  }

  // TODO remove this as it is no longer needed
  requestInvitation$({
    email,
    investment,
    name
  }: {
    email: string;
    investment: ID;
    name: string;
  }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/send_user_email_invitation/${investment}`, {
      email,
      name
    });
  }

  signupViaInvitation$(
    token: string,
    credentials: { email: string; password: string; name: string }
  ): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/signup_via_invitation/${token}`, credentials);
  }

  signupAndAcceptInvitation$({
    invitation,
    name,
    password
  }: {
    invitation: Invitation;
    name: string;
    password: string;
  }): Observable<any> {
    let { email } = invitation;
    return this.signupViaInvitation$(invitation.token, { email, name, password });
  }

  updateInvestmentInvitation$(
    invitationId: string,
    message_params: any,
    sent_to: string,
    name: string
  ): Observable<any> {
    let url = `${environment.apiUrl}/v1/invitations/${invitationId}`;
    let params = {
      message_params,
      name,
      sent_to
    };
    return this.http.put(url, params);
  }
}
