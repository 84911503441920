<header>
  <h1>
    <a href="/" inlineSVG="/assets/flow_white.svg">
      Flow
    </a>
  </h1>
  <h2>Your OS for Alternative Assets</h2>
</header>

<main>
  <router-outlet></router-outlet>
</main>
