import { Pipe, PipeTransform } from '@angular/core';

import { ImageColumn, ColumnLike, ColumnType } from '@component/collection-table/collection-table.model';

@Pipe({
  name: 'asImageColumn'
})
export class AsImageColumnPipe implements PipeTransform {
  transform(column: ColumnLike): ImageColumn | false {
    if (column.type === ColumnType.Image) return column as ImageColumn;
    return false;
  }
}
