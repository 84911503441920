import { createStore, filterNil, select, setProp, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

import { EntityMember } from '@state/entities';
import { ID } from '@state/base';

import { Investor, InvestorDocument, InvestorTask } from './investor.model';

interface InvestorPops {
  investor?: Investor;
  investmentAccountDocuments?: InvestorDocument[];
  investorDocuments?: InvestorDocument[];
  investorMembers?: EntityMember[];
  investorTasks?: InvestorTask[];
  stagedDocuments?: InvestorDocument[];
}

let initialState: InvestorPops = {};

@Injectable({ providedIn: 'root' })
export class InvestorRepository {
  private _store$ = createStore({ name: 'investor' }, withProps<InvestorPops>(initialState));

  investmentAccountDocuments$ = this._store$.pipe(
    select(({ investmentAccountDocuments }) => investmentAccountDocuments),
    filterNil()
  );
  investor$ = this._store$.pipe(select(({ investor }) => investor));
  investorDocuments$ = this._store$.pipe(
    select(({ investorDocuments }) => investorDocuments),
    filterNil()
  );
  investorTasks$ = this._store$.pipe(select(({ investorTasks }) => investorTasks));
  stagedDocuments$ = this._store$.pipe(
    select(({ stagedDocuments }) => stagedDocuments),
    filterNil()
  );

  getInvestor(): Investor {
    return this._store$.getValue().investor;
  }

  getInvestorIAEntityId(): ID {
    return this._store$.getValue().investor?.investment_account_entity_id;
  }

  setInvestmentAccountDocuments(investmentAccountDocuments: InvestorDocument[]): void {
    this._store$.update(setProp('investmentAccountDocuments', investmentAccountDocuments));
  }

  setInvestor(investor: Investor): void {
    this._store$.update(setProp('investor', investor));
  }

  setInvestorDocuments(investorDocuments: InvestorDocument[]): void {
    this._store$.update(setProp('investorDocuments', investorDocuments));
  }

  setInvestorMembers(investorMembers: EntityMember[]): void {
    this._store$.update(setProp('investorMembers', investorMembers));
  }

  setInvestorTasks(investorTasks: InvestorTask[]): void {
    this._store$.update(setProp('investorTasks', investorTasks));
  }

  setStagedDocuments(stagedDocuments: InvestorDocument[]): void {
    this._store$.update(setProp('stagedDocuments', stagedDocuments));
  }

  updateInvestorDocumentTags(document: InvestorDocument, tags: string[]): void {
    this._store$.update((state) => {
      let investorDocuments = state.investorDocuments.map((investorDocument) => ({ ...investorDocument }));
      investorDocuments.find(({ id }) => id === document.id).tags = tags;
      return { ...state, investorDocuments };
    });
  }
}
