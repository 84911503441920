import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { ApplicationService } from '@state/application';
import { environment } from '@env/environment';
import { LaunchDarklyRepository } from '@state/launch-darkly';
import { Investment, InvestmentService } from '@state/investment';

@Injectable({
  providedIn: 'root'
})
export class InvestmentResolver  {
  constructor(
    private applicationService: ApplicationService,
    private launchDarklyRepository: LaunchDarklyRepository,
    private investmentService: InvestmentService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Investment> {
    let investmentId: string = route.params.investment_id;
    let providerEntityId: string = route.params.provider_entity_id;
    this.applicationService.pushLoading('investment');

    return this.launchDarklyRepository.ready$.pipe(
      switchMap(() => this.investmentService.getAndStoreInvestment$(investmentId, providerEntityId)),
      switchMap(
        (investment) =>
          new Observable<Investment>(() => {
            let { vehicle, id } = investment;
            let { fliUrl } = environment;
            let orgUrlSlug = vehicle.organization_url_slug;

            if (orgUrlSlug) {
              window.location.href = `${fliUrl}/g/${orgUrlSlug}/investments/${id}`;
            } else {
              // Need to create a NOT NULL constraint at the DB level,
              // until we do we need to route to FLI root
              window.location.href = fliUrl;
            }
          })
      ),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        void this.router.navigate(['/404']);
        return EMPTY;
      }),
      finalize(() => this.applicationService.popLoading('investment'))
    );
  }
}
