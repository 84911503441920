import { Pipe, PipeTransform } from '@angular/core';

import { LinkColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asLinkColumn'
})
export class AsLinkColumnPipe implements PipeTransform {
  transform(column: ColumnLike): LinkColumn | false {
    if (column.type === ColumnType.Link) return column as LinkColumn;
    return false;
  }
}
