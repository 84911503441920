import 'froala-editor/js/plugins.pkgd.min';

import { CommonModule } from '@angular/common';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgModule } from '@angular/core';

import { FroalaEditorComponent } from './froala-editor.component';

@NgModule({
  declarations: [FroalaEditorComponent],
  exports: [
    FroalaEditorComponent
  ],
  imports: [CommonModule, FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()]
})

export class FroalaModule {}
