import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { FundDocument, VisibilityDocument } from './fund-document.model';
import { FundDocumentRepository } from './fund-document.repository';

export enum FundDocumentApproval {
  Approve = 'approve',
  Reject = 'reject'
}

export interface FundDocumentApprovalOptions {
  documentId: ID;
  vehicleId: ID;
  decision: FundDocumentApproval;
}

interface VehicleVisibilityDocumentParams {
  allow_download: boolean;
  document_id?: ID;
  vehicle_id?: ID;
}

@Injectable({ providedIn: 'root' })
export class FundDocumentService {
  constructor(private http: HttpClient, private fundDocumentRepository: FundDocumentRepository) {}

  approve$({ documentId, vehicleId, decision }: FundDocumentApprovalOptions): Observable<FundDocument> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicleId}/documents/${documentId}/approve_staged_document`;
    return this.http.post<FundDocument>(url, { decision });
  }

  approveAndStore$({
    documentId,
    vehicleId,
    decision
  }: FundDocumentApprovalOptions): Observable<FundDocument> {
    return this.approve$({ decision, documentId, vehicleId }).pipe(
      tap({
        next: (document) => {
          this.fundDocumentRepository.updateDocument(document);
        }
      })
    );
  }

  createAndStoreVisibilityDocument$(params: VehicleVisibilityDocumentParams): Observable<VisibilityDocument> {
    return this.http
      .post<VisibilityDocument>(`${environment.apiUrl}/v1/vehicle_visibility_documents`, params)
      .pipe(
        tap({
          next: (vehicle_visibility_document) => {
            this.fundDocumentRepository.updateVehicleVisibilityDocument(vehicle_visibility_document);
          }
        })
      );
  }

  destroyVisibilityDocument$(vehicle_visibility_document_id: ID): Observable<null> {
    return this.http
      .delete<null>(`${environment.apiUrl}/v1/vehicle_visibility_documents/${vehicle_visibility_document_id}`)
      .pipe(
        tap({
          next: () => {
            this.fundDocumentRepository.removeVehicleVisibilityDocument();
          }
        })
      );
  }

  get$(id: ID, vehicle_id: ID, provider_entity_id?: ID): Observable<FundDocument> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/documents/${id}`;

    let options = {};
    if (provider_entity_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_entity_id as string });
      options = { headers };
    }

    return this.http.get<FundDocument>(url, options);
  }

  getAndStore$(id: ID, vehicle_id: ID, provider_entity_id?: ID): Observable<FundDocument> {
    return this.get$(id, vehicle_id, provider_entity_id).pipe(
      tap({
        next: (document) => {
          this.fundDocumentRepository.setDocument(document);
        }
      })
    );
  }

  getVisibilityDocument$(vehicle_visibility_document_id: ID): Observable<VisibilityDocument> {
    return this.http.get<VisibilityDocument>(
      `${environment.apiUrl}/v1/vehicle_visibility_documents/${vehicle_visibility_document_id}`
    );
  }

  updateAndStoreVisibilityDocument$(
    vehicle_visibility_document_id: ID,
    params: Partial<VehicleVisibilityDocumentParams>
  ): Observable<VisibilityDocument> {
    return this.http
      .put<VisibilityDocument>(
        `${environment.apiUrl}/v1/vehicle_visibility_documents/${vehicle_visibility_document_id}`,
        params
      )
      .pipe(
        tap({
          next: (vehicle_visibility_document) => {
            this.fundDocumentRepository.updateVehicleVisibilityDocument(vehicle_visibility_document);
          }
        })
      );
  }
}
