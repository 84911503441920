<header class="header">
  <a routerLink="/dashboard" inlineSVG="/assets/logo_primary_rgb.svg"> Flow </a>
  <h1>
    <span *ngIf="title$ | async">{{ title$ | async }}</span>
  </h1>
  <div class="controls">
    <ng-container *ngIf="controls$ | async as controls">
      <button
        *ngFor="let control of controls"
        (click)="control.click()"
        [attr.data-testid]="control.label | function : controlButtonTestId"
        [class.transparent]="control.style === ControlStyle.Transparent"
        [class.warning]="control.style === ControlStyle.Warning"
      >
        {{ control.label }}
      </button>
    </ng-container>
  </div>
</header>
<main>
  <ng-content></ng-content>
</main>
