import { Pipe, PipeTransform } from '@angular/core';

import { TagsColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asTagsColumn'
})
export class AsTagsColumnPipe implements PipeTransform {
  transform(column: ColumnLike): TagsColumn | false {
    if (column.type === ColumnType.Tags) return column as TagsColumn;
    return false;
  }
}
