import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { FormValues } from '@state/form-wizard';

import { SignatureRequestStore } from './signature-request.store';
import { FormTask, SignatureRequest, SignatureRequestPeek } from './signature-request.model';

export interface SignatureRequestRequestOptions {
  signature_request_form_id: string | ID;
  signature_request_id: string | ID;
}

export interface FormParams {
  assignable_id?: ID;
  updated_form_values?: FormValues;
  form_id?: ID;
  form_values?: FormValues;
  request_token?: string;
  section_form_values?: FormValues;
}

@Injectable({ providedIn: 'root' })
export class SignatureRequestService {
  constructor(private http: HttpClient, private store: SignatureRequestStore) {}

  peek$(token: string): Observable<SignatureRequestPeek> {
    let url = `${environment.apiUrl}/signature_requests/${token}/peek`;
    return this.http.get<SignatureRequestPeek>(url);
  }

  get$(token: string): Observable<SignatureRequest> {
    let url = `${environment.apiUrl}/signature_requests/${token}/show_by_token`;
    return this.http.get<SignatureRequest>(url);
  }

  getAndStore$(token: string): Observable<SignatureRequest> {
    return this.get$(token).pipe(
      tap({
        next: (signatureRequest: SignatureRequest) => {
          this.store.update({ signatureRequest });
        }
      })
    );
  }

  getAndStoreForm$({
    assignable_id,
    form_id,
    token
  }: {
    assignable_id?: ID;
    form_id: ID;
    token: string;
  }): Observable<FormTask> {
    let url = `${environment.apiUrl}/signature_requests/${token}/form/${form_id}`;
    if (assignable_id) url += `?assignable_id=${assignable_id}`;

    return this.http.get<FormTask>(url).pipe(
      tap({
        next: (formTask: FormTask) => {
          let { actor, defaults: formValues, form } = formTask;

          // Need to filter out form elements that are not applicable to current actor
          form.sections = form.sections.map((section) => {
            section.elements = section.elements.filter((element) => element.role === actor.role);
            return section;
          });

          this.store.update({ form, formValues });
        }
      })
    );
  }

  removeSignatureRequestForm$({
    signature_request_id,
    signature_request_form_id
  }: SignatureRequestRequestOptions): Observable<null> {
    // eslint-disable-next-line max-len
    let url = `${environment.apiUrl}/v1/signature_requests/${signature_request_id}/signature_request_forms/${signature_request_form_id}`;
    return this.http.delete<null>(url, {});
  }

  reset(): void {
    this.store.reset();
  }

  setWrongActor(wrongActor: boolean): void {
    this.store.update({ wrongActor });
  }

  submitAndRemoveForm$({
    assignable_id,
    updated_form_values,
    form_id,
    form_values,
    request_token,
    section_form_values
  }: FormParams): Observable<null> {
    let url = `${environment.apiUrl}/signature_requests/${request_token}/form/${form_id}`;

    return this.http
      .post<null>(url, { assignable_id, form_values, section_form_values, updated_form_values })
      .pipe(
        tap({
          next: () => {
            this.store.update({ form: undefined });
          }
        })
      );
  }

  updateFormValues$({
    assignable_id,
    updated_form_values,
    form_id,
    form_values,
    request_token,
    section_form_values
  }: FormParams): Observable<any> {
    let url = `${environment.apiUrl}/signature_requests/${request_token}/form/${form_id}/update_values`;
    return this.http.post(url, { assignable_id, form_values, section_form_values, updated_form_values });
  }

  updateAndStoreFormValues$(updatedFormParams: FormParams): Observable<any> {
    return this.updateFormValues$(updatedFormParams);
  }
}
