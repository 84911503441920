import { BadgeColor } from '@component/badge';
import { ID } from '@datorama/akita';
import { QueryParams } from '@state/query-params';

export type ColumnBooleanFunction = (obj: any) => boolean;
export type ColumnFunction = (obj: any) => string | number | boolean;
export type ColumnLinkFunction = (obj: any) => string | ID | (string | ID)[];
export type ColumnStatusFunction = (obj: any) => StatusValueObject;
export type ColumnTagsFunction = (obj: any) => string[];

export type ColumnLinkValue = string | ID | ColumnLinkFunction | (string | ID)[];
export enum ColumnType {
  Action = 'action',
  Bulk = 'bulk',
  Date = 'date',
  FundInvestorStatus = 'fund-investor-status',
  Icon = 'icon',
  IconBadge = 'icon-badge',
  IconLink = 'icon-link',
  IconLinkMeta = 'icon-link-meta',
  IconMeta = 'icon-meta',
  Image = 'image',
  ImageMeta = 'image-meta',
  Link = 'link',
  LinkMeta = 'link-meta',
  ListOfLinks = 'list-of-links',
  Meta = 'meta',
  MetaSelect = 'meta-select',
  Owner = 'owner',
  SlideToggle = 'slide-toggle',
  Tags = 'tags',
  Tooltip = 'tooltip',
  ValueBadge = 'value-badge',
  ValueTags = 'value-tags'
}

export type ColumnValue = string | ColumnFunction;

export type ColumnValueOption =
  | ColumnStatusFunction
  | ColumnValue
  | IconBadgeValues
  | IconLinkMetaValues
  | IconLinkValues
  | IconMetaMenuValues
  | IconMetaValues
  | IconValues
  | ImageMetaValues
  | ImageValues
  | LinkValues
  | ListOfLinkValues
  | MetaSelectValues
  | MetaValues
  | SlideToggleValues
  | TagsValues
  | TooltipValues
  | ValueBadgeValues
  | ValueTagsValues;

export type LinkTarget = '_self' | '_blank' | '_parent' | '_top';

export interface Link {
  label: string;
  route: (string | ID)[];
  target?: LinkTarget;
}

export enum ActionType {
  Clipboard = 'clipboard',
  Menu = 'menu',
  SingleAction = 'single-action',
  SingleActionButton = 'single-action-button'
}
export interface ActionValues {
  condition?: (obj: any) => boolean;
  copyContent?: (row: any) => string;
  icon?: ColumnValue;
  label?: ((obj: any) => string) | string;
  menu?: MenuItem[];
  method?: (obj: any, i: number) => void;
  onCopySuccess?: (isSuccess: boolean) => void;
  tooltip?: string;
  type: ActionType;
}

export interface MenuItem {
  action?: (obj?: any) => void;
  class?: string;
  condition?: (obj?: any) => boolean;
  copyAction?: (obj?: any) => any;
  disabled?: boolean;
  divider?: boolean;
  icon?: string;
  label?: ((obj: any) => string) | string;
  routerLink?: (obj?: any) => (string | number)[] | string;
  successCallback?: (isSuccess: boolean) => void;
  type?: string;
}

export interface CellMenuItem {
  action: (obj?: any) => void;
  className?: string;
  condition?: (obj?: any) => boolean;
  disabled?: (obj?: any) => boolean;
  label: ((obj?: any) => string) | string;
  meta?: string;
}

export interface FundInvestorStatusValues {
  apiActionCallback: () => void;
}

export interface IconBadgeValues {
  color: BadgeColor | ((...args: any[]) => BadgeColor);
  icon: ColumnValue;
  main: ColumnValue;
}

export interface IconLinkValues {
  icon: ColumnValue;
  link: ColumnLinkValue;
  main: ColumnValue;
  target?: string;
}

export interface IconLinkMetaValues {
  icon: ColumnValue;
  link: ColumnLinkValue;
  main: ColumnValue;
  meta: ColumnValue;
  target?: string;
}

export interface LinkValues {
  link: ColumnLinkValue;
  main: ColumnValue;
  queryParams?: (obj: any) => QueryParams;
  target?: LinkTarget;
}

export interface LinkMetaValues {
  link: ColumnLinkValue;
  main: ColumnValue;
  meta: ColumnValue;
  target?: LinkTarget;
}

export interface IconValues {
  icon: ColumnValue;
  main: ColumnValue;
}

export interface IconMetaValues {
  icon: ColumnValue;
  main: ColumnValue;
  meta: ColumnValue;
}

export interface IconMetaMenuValues {
  icon: ColumnValue;
  main: ColumnValue;
  menu: CellMenuItem[];
  menuGenerator?: (obj: any, menu: CellMenuItem[], trigger: HTMLButtonElement) => void;
  meta: ColumnValue;
}

export interface ImageValues {
  image: ColumnValue;
  main: ColumnValue;
}

export interface ImageMetaValues {
  image: ColumnValue;
  main: ColumnValue;
  meta: ColumnValue;
}

export interface MetaValues {
  main: ColumnValue;
  meta: ColumnValue;
}

export interface MetaSelectValues {
  main: ColumnValue;
  menu: CellMenuItem[];
  meta: ColumnValue;
  metaMenu?: boolean;
  placeholder?: string;
}

export interface ListOfLinkValues {
  main: (obj: object) => Link[] | string;
  maxShown?: number;
  modelName?: string;
  truncated?: boolean;
}

export interface SlideToggleValues {
  action: (obj: any) => void;
  checked: boolean | string | ColumnBooleanFunction;
  label: ColumnValue;
}

export interface StatusValueObject {
  badge?: string;
  icon?: string;
  iconSeries?: StatusIconObject;
  label?: ColumnValue;
}

export interface StatusIconObject {
  [key: string]: StatusIconValues;
}

export interface StatusIconValues {
  complete: boolean;
  icon: string;
  tooltip: string;
}

export type TagsValues = string | string[] | ColumnTagsFunction;

export interface TooltipValues {
  main: ColumnValue;
  tooltip?: ColumnValue;
}

export interface ValueBadgeValues {
  main: ColumnValue;
  badgeCondition: ColumnBooleanFunction | boolean | string;
  badgeLabel: ColumnValue;
}

export interface ValueTagsValues {
  main: ColumnValue;
  tags: string | string[] | ColumnTagsFunction;
}

export interface Column {
  condition?: (obj: any) => boolean;
  def: string;
  disableSort?: boolean;
  footerValue?: string | number;
  header?: string;
  headerTooltip?: string;
  isSticky?: boolean;
  isStickyEnd?: boolean;
  lineClamp?: 1 | 2 | 3;
  numeric?: boolean;
  testId?: string;
  type?: ColumnType;
  value?: ColumnValueOption;
}

// Not alphabetized because it has to come after Column
export interface ActionColumn extends Column {
  action: ActionValues;
  condition?: (obj: any) => boolean;
  type: ColumnType.Action;
}

export interface DateColumn extends Column {
  type: ColumnType.Date;
  value: string;
}

export interface FundInvestorStatusColumn extends Column {
  type: ColumnType.FundInvestorStatus;
  value: ColumnStatusFunction;
}

export interface IconBadgeColumn extends Column {
  type: ColumnType.IconBadge;
  value: IconBadgeValues;
}

export interface IconColumn extends Column {
  type: ColumnType.Icon;
  value: IconValues;
}

export interface IconLinkColumn extends Column {
  type: ColumnType.IconLink;
  value: IconLinkValues;
}

export interface IconLinkMetaColumn extends Column {
  type: ColumnType.IconLinkMeta;
  value: IconLinkMetaValues;
}

export interface IconMetaColumn extends Column {
  type: ColumnType.IconMeta;
  value: IconMetaValues;
}

export interface ImageColumn extends Column {
  type: ColumnType.Image;
  value: ImageValues;
}

export interface ImageMetaColumn extends Column {
  type: ColumnType.ImageMeta;
  value: ImageMetaValues;
}

export interface LinkColumn extends Column {
  type: ColumnType.Link;
  value: LinkValues;
}

export interface LinkMetaColumn extends Column {
  type: ColumnType.LinkMeta;
  value: LinkMetaValues;
}

export interface ListOfLinksColumn extends Column {
  type: ColumnType.ListOfLinks;
  value: ListOfLinkValues;
}

export interface MetaColumn extends Column {
  type: ColumnType.Meta;
  value: MetaValues;
}

export interface MetaSelectColumn extends Column {
  type: ColumnType.MetaSelect;
  value: MetaSelectValues;
}

export interface SlideToggleColumn extends Column {
  type: ColumnType.SlideToggle;
  value: SlideToggleValues;
}

export interface TagsColumn extends Column {
  type: ColumnType.Tags;
  value: TagsValues;
}

export interface OwnerColumn extends Column {
  type: ColumnType.Owner;
  value: TooltipValues;
}

export interface TooltipColumn extends Column {
  type: ColumnType.Tooltip;
  value: TooltipValues;
}

export interface ValueBadgeColumn extends Column {
  type: ColumnType.ValueBadge;
  value: ValueBadgeValues;
}

export interface ValueTagsColumn extends Column {
  type: ColumnType.ValueTags;
  value: ValueTagsValues;
}

export type ColumnLike =
  | ActionColumn
  | Column
  | DateColumn
  | FundInvestorStatusColumn
  | IconBadgeColumn
  | IconColumn
  | IconLinkColumn
  | IconLinkMetaColumn
  | IconMetaColumn
  | ImageColumn
  | ImageMetaColumn
  | LinkColumn
  | LinkMetaColumn
  | ListOfLinksColumn
  | MetaColumn
  | MetaSelectColumn
  | SlideToggleColumn
  | TagsColumn
  | TooltipColumn
  | ValueBadgeColumn
  | ValueTagsColumn;

export interface RowSettings {
  action?: (obj: any, event: MouseEvent) => void;
  active?: (obj: any) => boolean;
  class?: (obj: any) => string;
}

export interface CollectionTable {
  columnSettings: ColumnLike[];
  customSort?: (obj: any, key: string) => any;
  dataSourceData: any[];
  matSortActive?: string;
  matSortDirection?: 'asc' | 'desc';
  showFooter?: boolean;
}

export interface BulkSelections<T> {
  [row: number]: T;
}
