import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasAny'
})
export class HasAnyPipe implements PipeTransform {
  transform(value: any[] | string): boolean {
    return value && !!value.length;
  }
}
