import { Injectable } from '@angular/core';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';

import { environment } from '@env/environment';
import { User, UserQuery } from '@state/user';

import { LaunchDarklyChangeset, LaunchDarklyRepository } from './launch-darkly.repository';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyService {
  context: LDContext;
  ldClient: LDClient;

  constructor(private launchDarklyRepository: LaunchDarklyRepository, private userQuery: UserQuery) {
    let { user } = this.userQuery.getValue();

    this.context = {
      kind: 'multi',
      user: this.createUserContext(user)
    };

    this.ldClient = initialize(environment.LAUNCH_DARKLY_CLIENT_SIDE_ID, this.context);

    this.ldClient.on('change', (flags: LaunchDarklyChangeset) => this.launchDarklyRepository.update(flags));

    this.ldClient.on('ready', () => this.setFlags());

    this.userQuery.select('user').subscribe({
      next: (newUser) => {
        if (newUser?.id !== (this.context as any).user.key) this.changeUser(newUser);
      }
    });
  }

  setFlags(): void {
    this.launchDarklyRepository.set(this.ldClient.allFlags());
  }

  changeUser(user: User): void {
    this.launchDarklyRepository.setReady(false);
    this.context = { ...this.context, user: this.createUserContext(user) };
    void this.ldClient.identify(this.context).then((flags) => {
      this.launchDarklyRepository.update(flags);
      this.launchDarklyRepository.setReady(true);
    });
  }

  createUserContext(user: User): LDContext {
    let userContext: LDContext;

    if (user?.id) {
      userContext = {
        key: user.id as string,
        kind: 'user'
      };
    } else {
      userContext = {
        anonymous: true,
        kind: 'user'
      };
    }

    return userContext;
  }
}
