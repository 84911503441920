import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TaskButtonComponent } from './task-button.component';

@NgModule({
  declarations: [TaskButtonComponent],
  exports: [TaskButtonComponent],
  imports: [CommonModule, RouterModule]
})
export class TaskButtonModule {}
