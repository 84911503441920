import { Pipe, PipeTransform } from '@angular/core';

import { ColumnTagsFunction } from '../../collection-table.model';

@Pipe({
  name: 'asTagsValue'
})
export class AsTagsValuePipe implements PipeTransform {
  transform(row: any, value: string | string[] | ColumnTagsFunction): string[] {
    if (typeof value === 'function') return value(row);
    if (typeof value === 'string') return row[value] as string[];
    if (Array.isArray(value)) return value;
    return [];
  }
}
