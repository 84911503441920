import moment from 'moment';

export const dateWithoutTime = 'MMMM D, YYYY';
export const dateWithoutTimeCondensed = 'MMM D, YYYY';
export const shortDate = 'MM/DD/YYYY';
export const isoDate = 'YYYY-MM-DD';
export const dateWithTime = 'MMMM D YYYY, h:mma';

export type MomentDate = Date | number | string;

export function isDateValid(date: MomentDate, format?: string): boolean {
  if (format) return moment(date, format, true).isValid();

  return moment(date).isValid();
}

export function isPastDate(date: Date | string): boolean {
  let argDate = moment(date, isoDate);
  let today = moment();

  return moment(argDate).isBefore(today) && !moment(argDate).isSame(today);
}

export function isFutureDate(date: Date | string): boolean {
  let argDate = moment(date);
  let today = moment();


  return moment(argDate).isAfter(today);
}

export function parseDate(value: MomentDate, format: string = dateWithTime, utc: boolean = false): string {
  if (!value) return;
  let dateTime = moment(value);

  // This handles cases where the server gives us a pure date object, but we need to display in local time
  if (dateTime.creationData().format === isoDate) return dateTime.utc(utc).local().format(format);

  // Otherwise, we respect the TZ info included with the date
  return dateTime.format(format);
}

// Necessary to disdable because TS / eslint are unaware of moment's specialized toString behavior
// eslint-disable-next-line @typescript-eslint/no-base-to-string
export const createTimestamp = (): string => parseDate(moment().toString());

export function parseToIsoDate(date: MomentDate): string {
  if (!date) return;
  return moment(date).format(isoDate);
}

export function parseToIso(date: MomentDate): string {
  if (!date) return;
  return moment(date).toISOString();
}
