import { Pipe, PipeTransform } from '@angular/core';

import { IconColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asIconColumn'
})
export class AsIconColumnPipe implements PipeTransform {
  transform(column: ColumnLike): IconColumn | false {
    if (column.type === ColumnType.Icon) return column as IconColumn;
    return false;
  }
}
