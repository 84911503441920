import { formatCurrency } from '@angular/common';

import { emailPattern } from '@helpers/validation-patterns';

import { convertCurrencyToNumber } from './number';

export function extractLeadingNumber(str: string): number | null {
  let num = str?.match(/^\d+/);
  return num ? parseInt(num[0], 10) : null;
}

export function getFlatNumber(str: string): number {
  if (!str) return;
  return Number(str.replace(/[$,.()% ]/g, ''));
}

export function isDollarAmount(str: string): boolean {
  if (!str) return;
  return /^\$/.test(str);
}

export function isPercentAmount(str: string): boolean {
  if (!str) return;
  return /%/.test(str);
}

export function joinValues(obj: object, values: string[], join = ' '): string {
  if (!obj || !values) return;
  let presentValues = [];

  for (let value of values) {
    if (obj[value]) presentValues.push(obj[value]);
  }

  return presentValues.join(join);
}

export function noIndent(str: string): string {
  return str.trim().replace(/\s\s+/g, ' ');
}

export function normalize(str: string): string {
  if (!str) return;
  return str.trim().toLowerCase();
}

export function splitComma(str: string): string[] {
  return str.replace(/\s/g, '').split(',');
}

export function titleize(str: string): string {
  if (!str) return;
  return str
    .split('_')
    .map((word) => {
      if (word === 'of') return word;
      if (word === 'id') return 'ID';
      return word.replace(/(^\w)|(\/\w)?/g, (c) => c.toUpperCase());
    })
    .join(' ');
}

export function toKebabCase(str: string, separator?: string): string {
  if (!str) return;
  return str
    .split(separator ? separator : ' ')
    .map((word) => word.toLowerCase())
    .join('-');
}

export function parseEmails(bccString: string, ccString: string): [string[], string[]] {
  let bcc_emails = bccString ? splitComma(bccString) : [];
  let cc_emails = ccString ? splitComma(ccString) : [];
  cc_emails = cc_emails.filter((email) => !!email);
  bcc_emails = bcc_emails.filter((email) => !!email);
  return [bcc_emails, cc_emails];
}

// TODO: Find a better place for this helper
export function invalidEmails(bccString: string, ccString: string): [string[], string[], string] {
  let [bcc_emails, cc_emails] = parseEmails(bccString, ccString);
  let invalidEmail: string;

  for (let email of [...cc_emails, ...bcc_emails]) {
    if (!emailPattern.test(email)) {
      invalidEmail = email;
      break;
    }
  }

  return [bcc_emails, cc_emails, invalidEmail];
}

export function toString(value: any): string {
  if (value === undefined || value === null) return '';
  let str: string = value.toString();
  return str;
}

export function toSnakeCase(str: string): string {
  if (!str) return;
  return normalize(str).replace(' ', '_');
}

export function toSentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// TODO: it's unclear what the data type this is expecting is, we should rename
export function getVariablesFrom(str: string): string[] {
  let keys = str.match(
    /(?:{{){1}(?:((?:PRIMARY|COUNTER)\_\d)\.)?(?:(?:([A-Z0-9_]+)(?:\.)?)([A-Z0-9_\.]*))((?:(?:\s\|\s)[a-zA-Z0-9_]+)+)*(?:}}){1}/g
  );

  if (keys) {
    return keys.map((key) =>
      key
        .replace(/[{}]|PRIMARY_\d\.|COUNTER_\d\./g, '')
        .split(' ')[0]
        .trim()
    );
  }

  return [];
}

export function truncate(str?: string, maxLength: number = 23): string {
  if (typeof str !== 'string') return;
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.substring(0, maxLength - 1)}...`;
}

export function formatCurrencyString(value: string): string {
  return formatCurrency(convertCurrencyToNumber(value), 'en-US', '$');
}

export function toPascalCase(str: string): string {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join('');
}
