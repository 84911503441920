import { Pipe, PipeTransform } from '@angular/core';

import { Link } from '../../collection-table.model';

@Pipe({
  name: 'asListOfLinksValues'
})
export class AsListOfLinksValuesPipe implements PipeTransform {
  transform(row: any, value: (obj: any) => Link[] | string): Link[] | false {
    // Supports case for recieving a link array function
    if (typeof value === 'function') {
      let links = value(row);
      if (Array.isArray(links) && links.length) return links;
    }

    // Supports case for recieving a completed link list from the row
    if (typeof value === 'string') {
      let rowObj = row[value];
      if (Array.isArray(rowObj) && rowObj.length) return rowObj as Link[];
    }

    // Supports case for recieving a completed link list from column settings
    if (Array.isArray(value) && value.length) return value;

    return false;
  }
}
