import * as filestack from 'filestack-js';
import { Client, PickerFileMetadata, PickerOptions, PickerResponse } from 'filestack-js';
import { Injectable } from '@angular/core';

import { ApplicationService } from '@state/application';
import { DocumentService } from '@app/shared/services';
import { environment } from '@env/environment';

export const SPREADSHEET_MIME_TYPES = [
  'text/csv',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

interface EntityLogoUpload {
  filePickerOptions?: PickerOptions;
  onUploadDone: () => void;
}

let fromSources = ['local_file_system', 'googledrive', 'dropbox', 'box'];

@Injectable({
  providedIn: 'root'
})
export class FilestackClient {
  private readonly _client: Client;

  constructor(private applicationService: ApplicationService, private documentService: DocumentService) {
    this._client = filestack.init(environment.FILESTACK_KEY);
  }

  get client(): Client {
    return this._client;
  }

  getUrl(file: PickerFileMetadata): string {
    return `https://${file.container}.s3.amazonaws.com/${file.key}`;
  }

  showDocumentPicker(options: any = {}, filestackOptions: PickerOptions = {}): void {
    let pickerOptions: PickerOptions = {
      fromSources,
      maxFiles: 1000,
      onUploadDone: (result: PickerResponse) => {
        let documents = [];

        for (let file of result.filesUploaded) {
          let { filename, mimetype: filetype, size: limit, key: source_url } = file;
          documents.push({ filename, filetype, limit, source_url, ...options.uploadParams });
        }

        let signDocumentParams = { documents, ...options.documentParams };

        this.applicationService.pushLoading('upload-documents');
        this.uploadDocument(signDocumentParams, options);
      },
      storeTo: {
        container: environment.FILESTACK_BUCKET,
        location: 's3',
        path: 'documents/',
        region: 'us-east-1'
      },
      uploadInBackground: false,
      ...filestackOptions
    };

    void this._client.picker(pickerOptions).open();
  }

  async showFilePicker(onUploadDone: (result: PickerResponse) => void): Promise<void> {
    await this._client
      .picker({
        fromSources,
        maxFiles: 1,
        onUploadDone,
        storeTo: {
          container: environment.FILESTACK_BUCKET,
          location: 's3',
          path: 'documents/',
          region: 'us-east-1'
        },
        uploadInBackground: false
      })
      .open();
  }

  showEntityLogoPicker({ onUploadDone, filePickerOptions }: EntityLogoUpload): void {
    if (!filePickerOptions) filePickerOptions = {};

    void this._client
      .picker({
        accept: 'image/*',
        fromSources,
        maxFiles: 1,
        onUploadDone,
        storeTo: {
          container: environment.FILESTACK_BUCKET,
          location: 's3',
          path: 'logos/',
          region: 'us-east-1'
        },
        ...filePickerOptions
      })
      .open();
  }

  uploadDocument(signDocumentParams: any, options: any): void {
    this.documentService.signUpload(signDocumentParams, {
      error: (error) => {
        console.error(error);
        this.applicationService.popLoading('upload-documents');
      },
      success: (data) => {
        if (options.onSuccess) options.onSuccess(data);
        this.applicationService.popLoading('upload-documents');
      }
    });
  }
}
