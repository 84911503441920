import { Pipe, PipeTransform } from '@angular/core';

import { FundInvestorStatusColumn, ColumnLike, ColumnType } from '@component/collection-table/collection-table.model';

@Pipe({
  name: 'asFundInvestorStatusColumn'
})
export class AsFundInvestorStatusColumnPipe implements PipeTransform {
  transform(column: ColumnLike): FundInvestorStatusColumn | false {
    if (column.type === ColumnType.FundInvestorStatus) return column as FundInvestorStatusColumn;
    return false;
  }
}
