import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { SessionQuery, SessionService, SessionStore } from '@state/session';
import { ZendeskService } from '@state/zendesk';

enum ValidationStatus {
  PendingValidation,
  Valid,
  Invalid
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  emailValidationStatus: ValidationStatus = ValidationStatus.PendingValidation;
  hasResetPassword$: Observable<boolean>;
  loginFormError$: Observable<string>;
  loginFormSubmitted$: Observable<boolean>;
  loginSuccess$: Observable<boolean>;
  requiresTokenVerification$: Observable<boolean>;
  resetForm: UntypedFormGroup;
  timeout: NodeJS.Timer;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ngZone: NgZone,
    private titleService: Title,
    public sessionQuery: SessionQuery,
    public sessionService: SessionService,
    public sessionStore: SessionStore,
    public zendeskService: ZendeskService
  ) {
    this.hasResetPassword$ = this.sessionQuery.select('hasResetPassword');
    this.loginFormError$ = this.sessionQuery.select('loginFormError');
    this.loginFormSubmitted$ = this.sessionQuery.select('loginFormSubmitted');
    this.loginSuccess$ = this.sessionQuery.select('loginSuccess');
    this.requiresTokenVerification$ = this.sessionQuery.select('requiresTokenVerification');
  }

  ngOnInit() {
    this.titleService.setTitle('Forgot Password | Flow');
    this.resetForm = this.formBuilder.group({
      email: [{ disabled: this.sessionQuery.getValue().loginFormSubmitted, value: '' }, [Validators.required]]
    });

    this.ngZone.runOutsideAngular(() => {
      this.zendeskService.toggleZendeskWidgetDisplay();
    });
  }

  ngOnDestroy() {
    this.zendeskService.toggleZendeskWidgetDisplay();
  }

  ngAfterViewInit(): void {
    let elName = '#reset-email-password';
    let el: HTMLElement = document.querySelector(elName);
    if (el) setTimeout(() => el.focus());
  }

  // convenience getter for easy access to reset form fields
  get f(): { [p: string]: AbstractControl } {
    return this.resetForm.controls;
  }

  get isEmailInvalid(): boolean {
    return this.emailValidationStatus === ValidationStatus.Invalid;
  }

  get isEmailValid(): boolean {
    return this.emailValidationStatus === ValidationStatus.Valid;
  }

  isBtnDisabled(): boolean {
    let isEmpty = !this.resetForm.controls.email.value;
    return this.isEmailInvalid || isEmpty;
  }

  validateEmailField(): void {
    let email: string = this.resetForm.controls.email.value;
    let { Invalid: invalid, Valid: valid } = ValidationStatus;
    this.emailValidationStatus = this.sessionService.testEmail(email) ? invalid : valid;
  }

  resetEmailValidation(): void {
    this.validateEmailField();
    if (this.isEmailValid) return;
    this.emailValidationStatus = ValidationStatus.PendingValidation;
  }

  submitEmailReset(resetToken?: boolean): void {
    this.validateEmailField();

    if (this.isEmailInvalid) {
      return this.ngAfterViewInit();
    }

    if (resetToken) this.sessionService.otpToken = undefined;
    this.sessionService.credentials.email = this.resetForm.value.email;
    this.sessionService.submitResetPassword(resetToken);
  }
}
