import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Action } from '@state/dashboard';
import { environment } from '@env/environment';
import { InvestorGPActionAction as InvestorAction } from '@state/investor';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-task-button',
  styleUrls: ['./task-button.component.scss'],
  templateUrl: './task-button.component.html'
})
export class TaskButtonComponent implements OnInit {
  @Input() action: Action | InvestorAction;
  @Input() apiActionCallback?: (action: Action | InvestorAction) => void;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  ngOnInit() {}

  ctaLink(action: Action): string {
    return action.url.replace(environment.docsUrl, '');
  }

  postApiAction(action: Action | InvestorAction, event?: MouseEvent): void {
    if (event) event.preventDefault();

    this.http.post(`${environment.apiUrl}/${action.url}`, {}).subscribe({
      next: () => {
        this.snackBar.open('Successfully Approved');
        if (this.apiActionCallback) this.apiActionCallback(this.action);
      }
    });
  }
}
