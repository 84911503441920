import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InvestmentAccountService } from '@state/investment-account';
import { OrganizationService } from '@state/organization';

interface Entity {
  entity_id: string;
}

@Injectable({ providedIn: 'root' })
export class EntityRedirectGuard  {
  previousPath: string;

  constructor(
    private investmentAccountService: InvestmentAccountService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  entityLoaded = (entity: Entity): void => {
    let previousPath = this.previousPath || 'entities';
    void this.router.navigate([`/e/${entity.entity_id}/${previousPath}`]);
  };

  handleError = (errorResponse: HttpErrorResponse): void => {
    if (errorResponse.status === 404) {
      void this.router.navigate(['/404']);
    } else {
      console.error(errorResponse);
    }
  };

  canActivate(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable(() => {
      let investmentAccountId: string = routeSnapshot.params.investment_account_id;
      let organizationId: string = routeSnapshot.params.organization_id;
      let { entityLoaded: next, handleError: error } = this;

      if (routeSnapshot.firstChild) {
        this.previousPath = routeSnapshot.firstChild.url.map((segment) => segment.path).join('/');
      }

      let subscriptionOptions = { error, next };

      if (organizationId) {
        this.organizationService.get$(organizationId).subscribe(subscriptionOptions);
      } else if (investmentAccountId) {
        this.investmentAccountService.get$(investmentAccountId).subscribe(subscriptionOptions);
      } else {
        void this.router.navigate(['/switch-profile']);
      }
    });
  }
}
