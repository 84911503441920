import { Pipe, PipeTransform } from '@angular/core';
import { StatusValueObject } from '@component/collection-table';

@Pipe({
  name: 'asStatusValues'
})
export class AsStatusValuesPipe implements PipeTransform {
  transform(row: any, value: any): StatusValueObject | false {
    if (typeof value === 'function') return value(row) as StatusValueObject;
    return false;
  }
}
