import { Pipe, PipeTransform } from '@angular/core';

import { ColumnLike, ColumnType, MetaSelectColumn } from '../../collection-table.model';

@Pipe({
  name: 'asMetaSelectColumn'
})
export class AsMetaSelectColumnPipe implements PipeTransform {
  transform(column: ColumnLike): MetaSelectColumn | false {
    if (column.type === ColumnType.MetaSelect) return column as MetaSelectColumn;
    return false;
  }
}
