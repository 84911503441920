import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-froala-editor',
  styleUrls: ['./froala-editor.component.scss'],
  templateUrl: './froala-editor.component.html'
})
export class FroalaEditorComponent implements OnInit {
  @Input() froalaEditor: any;
  @Input() froalaModel: string;
  @Output() froalaModelChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onFroalaModelChange($event: string): void {
    this.froalaModelChange.emit($event);
  }
}
