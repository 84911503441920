import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { MapperComponent } from '@app/shared/components/mapper/mapper.component';
import { PageObject } from '@state/document';



@Injectable({
  providedIn: 'root'
})
export class MapperService {
  // TODO: implement Canvas interface, possibly through newer version of the library
  mappers: MapperComponent[] = [];

  constructor() {}

  addMapper(canvas: MapperComponent): void {
    this.mappers.push(canvas);
  }

  moveFieldForPageObject(pageObject: PageObject, sourcePageId: ID, destinationPageId: ID): void {
    let sourceCanvas = this.mappers.find((mapper) => mapper.page.id === sourcePageId)?.canvas;
    let targetMapper = this.mappers.find((mapper) => mapper.page.id === destinationPageId);

    sourceCanvas.remove(sourceCanvas.getActiveObject());
    targetMapper.renderFields();
  }

  reset(): void {
    this.mappers = [];
  }
}
