import { Pipe, PipeTransform } from '@angular/core';

import { LinkMetaColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asLinkMetaColumn'
})
export class AsLinkMetaColumnPipe implements PipeTransform {
  transform(column: ColumnLike): LinkMetaColumn | false {
    if (column.type === ColumnType.LinkMeta) return column as LinkMetaColumn;
    return false;
  }
}
