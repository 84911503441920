import { Injectable } from '@angular/core';
import Pusher, { Channel, Options } from 'pusher-js';

import { environment } from '@env/environment';
import { PusherFacade } from './pusher.facade';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  channels: Channel[];
  pusher: Pusher;

  constructor() {
    let appKey = environment.PUSHER_KEY;

    let options: Options = {
      cluster: environment.PUSHER_CLUSTER,
      encrypted: true
    };

    this.pusher = PusherFacade.create(appKey, options);

    // Can be toggled on for debugging purposes
    // if (!environment.production) {
    //   Pusher.logToConsole = true;
    // }
  }
}
