import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthorizationLayoutComponent } from './authorization-layout.component';

@NgModule({
  declarations: [AuthorizationLayoutComponent],
  exports: [AuthorizationLayoutComponent, RouterModule],
  imports: [CommonModule, InlineSVGModule, RouterModule]
})
export class AuthorizationLayoutModule {}
