<div class="mat-dialog-header">
  <h1>Create New Variable</h1>
</div>

<div mat-dialog-content>
  <form #newVariableForm="ngForm">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Key</mat-label>
      <input matInput type="text"
             autocomplete="off" spellcheck="off"
             [(ngModel)]="variableToCreate.key"
             [required]="true"
             name="variable_key"
             cdkFocusInitial>
    </mat-form-field>
    <p class="help-text">
      Keys are unique and must start with a letter.
    </p>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Data Type</mat-label>
      <mat-select
        [(ngModel)]="variableToCreate.type"
        [required]="true"
        floatLabel="always"
        name="variable_type"
      >
        <mat-option value="boolean">Boolean</mat-option>
        <mat-option value="currency">Currency</mat-option>
        <mat-option value="date_time">Date/Time</mat-option>
        <mat-option value="document">Document</mat-option>
        <mat-option value="enumeration">Enumeration</mat-option>
        <mat-option value="float">Float</mat-option>
        <mat-option value="integer">Integer</mat-option>
        <mat-option value="string">String</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-slide-toggle
      [(ngModel)]="variableToCreate.obfuscated"
      color="primary"
      name="variable_obfuscated"
    >Protected Value?</mat-slide-toggle>

    <p class="hint">
      Protected values are one-way inputs. Users will never be able to see these values once they are submitted. E.g. credit card numbers or tax id
    </p>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Description</mat-label>
      <textarea
        [(ngModel)]="variableToCreate.description"
        matInput type="text"
        name="variable_description"
      ></textarea>
    </mat-form-field>

    <button (click)="onNoClick()" class="close">Close</button>
    <button
      type="submit"
      cdkFocusInitial
      [disabled]="!variableToCreate.type  || !variableToCreate.key"
      (click)="createCustomVariable()"
    >Submit</button>
  </form>
</div>
