import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concatMap, delay, finalize, tap } from 'rxjs/operators';
import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import zxcvbn, { ZXCVBNScore } from 'zxcvbn';

import { ApplicationService } from '@state/application';
import { Invitation, InvitationQuery, InvitationService } from '@state/invitation';
import { MessageBoxType } from '@component/message-box';
import { PasswordValidator } from '@helpers/password.validator';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '@global';
import { SessionService } from '@state/session';
import { standardError$ } from '@helpers/error';
import { ZendeskService } from '@state/zendesk';

export enum SignupView {
  RequestInvitation = 'request-invitation'
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-signup',
  styleUrls: ['./signup.component.scss'],
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit, OnDestroy {
  invitation$: Observable<Invitation>;
  messageBoxType = MessageBoxType;
  // TODO remove this reference and associated html as it is not invocable
  requestInvitation$: Observable<any>;
  requestInvitationForm: UntypedFormGroup;
  sentInvitation = false;
  signAndAcceptInvitation$: Observable<any>;
  signUpForm: UntypedFormGroup;

  readonly PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;
  readonly TERMS_OF_USE_URL = TERMS_OF_USE_URL;

  constructor(
    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private invitationQuery: InvitationQuery,
    private invitationService: InvitationService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private snackbar: MatSnackBar,
    private titleService: Title,
    public zendeskService: ZendeskService
  ) {}

  ngOnInit() {
    this.applicationService.pushLoading('signup');
    this.invitation$ = this.invitationQuery.selectInvitation$.pipe(tap({ next: this.onLoad }));
    this.checkForInvitation();
    this.titleService.setTitle('Signup | Flow');
    this.signUpForm = this.formBuilder.group(
      {
        confirmPassword: ['', [Validators.required]],
        email: [{ disabled: true, value: '' }, [Validators.email]],
        // eslint-disable-next-line rxjs/finnish
        name: [{ disabled: this.signAndAcceptInvitation$, value: '' }, [Validators.required]],
        password: [
          // eslint-disable-next-line rxjs/finnish
          { disabled: this.signAndAcceptInvitation$, value: '' },
          [Validators.required, PasswordValidator.strength]
        ],
        termsAgreement: [false, [Validators.requiredTrue]]
      },
      { validators: PasswordValidator.confirm }
    );

    this.ngZone.runOutsideAngular(() => {
      this.zendeskService.toggleZendeskWidgetDisplay();
    });
  }

  ngOnDestroy() {
    this.zendeskService.toggleZendeskWidgetDisplay();
  }

  get passwordScore(): ZXCVBNScore {
    let password: string = this.signUpForm.get('password').value;
    return zxcvbn(password).score;
  }

  onLoad = (invitation: Invitation): void => {
    if (!invitation) return;

    let { email, name } = invitation;
    this.signUpForm.patchValue({
      email,
      name
    });
  };

  checkForInvitation(): void {
    let token: string = this.route.snapshot.queryParams.token;
    let view: SignupView = this.route.snapshot.queryParams.view;
    let { invitation } = this.invitationQuery.getValue();
    if (invitation) this.applicationService.popLoading('signup');

    if (token) {
      this.invitationService.peek$(token).subscribe({
        next: () => {
          this.applicationService.popLoading('signup');
        }
      });
    } else if (view === SignupView.RequestInvitation) {
      this.requestInvitationForm = this.formBuilder.group({
        // eslint-disable-next-line rxjs/finnish
        email: [{ disabled: this.requestInvitation$, value: '' }, [Validators.email]],
        // eslint-disable-next-line rxjs/finnish
        name: [{ disabled: this.requestInvitation$, value: '' }, [Validators.required]]
      });
      this.applicationService.popLoading('signup');
    } else {
      void this.router.navigate(['/login']);
    }
  }

  onSubmit(invitation: Invitation): void {
    if (this.signUpForm.invalid) return;

    let name = this.signUpForm.get('name').value;
    let password = this.signUpForm.get('password').value;
    let data = { invitation, name, password };
    let updatedInvitation = { ...invitation, name, password };

    // TODO: Investigate how many of these functions already exist on session service and use those.
    this.signAndAcceptInvitation$ = this.invitationService.signupAndAcceptInvitation$(data).pipe(
      concatMap(() => this.invitationService.acceptInvitation$(updatedInvitation)),
      tap({
        next: (response: HttpResponse<any>) => {
          let authToken: string = response['auth_token'];
          this.sessionService.setSession(authToken);
          this.invitationService.redirectToFinalDestination(invitation);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) this.applicationService.setNotFound();
        return of({});
      })
    );
  }

  sendInvitationRequest(): void {
    if (this.requestInvitationForm.invalid) return;
    let name = this.requestInvitationForm.get('name').value;
    let email = this.requestInvitationForm.get('email').value;

    let { redirectUrl } = this.route.snapshot.queryParams;
    let investment = redirectUrl.match(
      /(\/investment\/)([0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12})/
    )[2];

    // Shorts the process if investment is missing, so we're not giving hints
    // to potential hackers on what's wrong
    if (!investment) {
      this.sentInvitation = true;
      return;
    }

    // TODO remove this reference and associated html as it is not invocable
    this.requestInvitation$ = this.invitationService.requestInvitation$({ email, investment, name }).pipe(
      delay(0),
      catchError((error: HttpErrorResponse) => standardError$(error)),
      finalize(() => (this.sentInvitation = true))
    );
  }
}
