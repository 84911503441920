import { createStore, select, withProps } from '@ngneat/elf';
import { filter, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DocumentPreviewType } from '@state/document';

import { FundDocument, VisibilityDocument } from './fund-document.model';

interface FundDocumentProps {
  document?: FundDocument;
}

const initialState: FundDocumentProps = {
  document: undefined
};

@Injectable({ providedIn: 'root' })
export class FundDocumentRepository {
  private _store$ = createStore(
    { name: 'fundDocument' },
    withProps<FundDocumentProps>(initialState)
  );

  document$ = this._store$.pipe(select((state) => state.document));
  documentPreviewType$: Observable<DocumentPreviewType> = this._store$.pipe(
    filter((state) => !!state.document),
    select(({ document }) => {
      let { preview, page_count, pages, title } = document;
      // The presence of a page count, implies it is a pdf document ready to render
      if (page_count && pages.length) return DocumentPreviewType.Document;

      // Preview is always preview
      if (preview) return DocumentPreviewType.HtmlPreview;

      // If there is no page count, at this point and the file is pdf, this means the file is still processing
      // (or has failed to process)
      if (/.*\.pdf$/.test(title)) return DocumentPreviewType.Processing;

      // All other types are downloadable
      return DocumentPreviewType.Download;
    })
  );

  constructor() {}

  getValue(): FundDocumentProps {
    return this._store$.getValue();
  }

  removeVehicleVisibilityDocument(): void {
    this.updateVehicleVisibilityDocument(undefined);
  }

  reset(): void {
    this._store$.reset();
  }

  setDocument(document: FundDocument): void {
    this.updateDocument(document);
  }

  updateDocument(document: FundDocument): void {
    this._store$.update((state) => ({ ...state, document }));
  }

  updateDocumentTitle(title: string): void {
    this._store$.update((state)  => {
      let document = { ...state.document, title };
      return { ...state, document };
    });
  }

  updateVehicleVisibilityDocument(vehicle_visibility_document?: VisibilityDocument): void {
    this._store$.update((state) => {
      if (!state.document) return state;

      let document = { ...state.document, vehicle_visibility_document };
      return { ...state, document };
    });
  }
}
