import { Pipe, PipeTransform } from '@angular/core';

import { SlideToggleColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asSlideToggleColumn'
})
export class AsSlideToggleColumnPipe implements PipeTransform {
  transform(column: ColumnLike): SlideToggleColumn | false {
    if (column.type === ColumnType.SlideToggle) return column as SlideToggleColumn;
    return false;
  }
}
