<h2>Unfortunately, this Flow cannot be found</h2>

<div inlineSVG="/assets/icons/ghost.svg"></div>

<ng-container *ngIf="(isUserLoggedIn$ | async) else notLoggedIn">
  <p>
    You've tried to access a page that either does not exist or your account does not have access to view.
  </p>
  <p>
    You're currently signed in as <strong *ngIf="user$ | async as user">{{ user.email }}</strong>.
  </p>
  <p>
    If you feel you've reached this in error, please reach out to <a href="mailto: support@flowinc.com">support&#64;flowinc.com</a>.
  </p>

  <fl-labeled-horizontal-separator label="or"></fl-labeled-horizontal-separator>

  <span>Click <a [routerLink]="['/dashboard']">here</a> to return home.</span>
</ng-container>

<ng-template #notLoggedIn>
  <p>
    You've tried to access a page that either does not exist or requires you to login to access.
  </p>
  <p>
    Please visit <a href="https://flowinc.app/login">https://flowinc.app/login</a> to login.
  </p>
  <p>
    If you feel you've reached this in error, please reach out to <a href="mailto: support@flowinc.com">support&#64;flowinc.com</a>.
  </p>
</ng-template>
