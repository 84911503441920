import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonePipe } from './none.pipe';

@NgModule({
  declarations: [NonePipe],
  exports: [NonePipe],
  imports: [CommonModule]
})
export class NoneModule {}
