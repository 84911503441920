import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export let initialInvestmentAccountState = {};

export function createInitialState(): object {
  return initialInvestmentAccountState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'investment-account', resettable: true })
export class InvestmentAccountStore extends Store<object> {
  constructor() {
    super(createInitialState());
  }
}
