import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asCellValue'
})
export class AsCellValuePipe implements PipeTransform {
  transform(row: any, value: any): string {
    // Handle Function values
    if (typeof value === 'function') {
      let result = value(row) || '–';
      if (typeof result === 'boolean') return result ? 'Yes' : 'No';
      return result?.trim() as string;
    }

    // Handle Boolean Values
    if (typeof row[value] === 'boolean') return row[value] ? 'Yes' : 'No';

    let str: string = row[value];

    // Otherwise default to good 'ol string coercion (or actual string)
    return typeof(str) === 'string' ? str?.trim() : '–';
  }
}
