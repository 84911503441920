import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApplicationService } from '@state/application';
import { Entity } from '@state/entities';
import { handleResolverError$ } from '@helpers/error';
import {
  MembershipEntityService,
  MembershipEntityRepository
} from '@state/membership-entity';
import { MembershipsService } from '@state/memberships';

@Injectable({
  providedIn: 'root'
})
export class MembershipEntityResolver  {
  constructor(
    private applicationService: ApplicationService,
    private membershipEntityService: MembershipEntityService,
    private membershipEntityRepository: MembershipEntityRepository,
    private membershipsService: MembershipsService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Entity> {
    this.applicationService.pushLoading('membership-entity');

    let activeMembershipEntity = this.membershipEntityRepository.getMembershipEntity();
    let membershipEntityId: string = route.params.membership_entity_id;
    let providerEntityId: string = route.params.provider_entity_id;

    // Reset store when not scoped under a membership-entity route
    // ie: /e/:membership_entity_id
    if (!membershipEntityId) {
      this.membershipEntityRepository.reset();
      return of(undefined);
    }

    // Skip getAndStoreMembershipEntity$ if the membership_entity_id belongs to the active membership entity
    if (membershipEntityId === activeMembershipEntity?.id) return;

    return this.membershipEntityService
      .getAndStoreMembershipEntity$(membershipEntityId, providerEntityId)
      .pipe(
        tap({ next: () => this.membershipsService.get(membershipEntityId, providerEntityId) }),
        catchError((error: HttpErrorResponse) => handleResolverError$(error, this.router)),
        finalize(() => this.applicationService.popLoading('membership-entity'))
      );
  }
}
