import { Pipe, PipeTransform } from '@angular/core';
import { MessageBoxType } from './message-box.component';

@Pipe({ name: 'messageBoxIcon' })
export class MessageBoxIconPipe implements PipeTransform {
  transform(type: MessageBoxType): string {
    let stringPaths = {
      alert: 'attention',
      complete: 'check-circle',
      info: 'info',
      warning: 'attention'
    };
    let iconName: string = stringPaths[type];

    return `/assets/icons/${iconName}.svg`;
  }
}
