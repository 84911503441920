import { Pipe, PipeTransform } from '@angular/core';

import { ActionColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asActionColumn'
})
export class AsActionColumnPipe implements PipeTransform {
  transform(column: ColumnLike): ActionColumn | false {
    if (column.type === ColumnType.Action) return column as ActionColumn;
    return false;
  }
}
