import { Pipe, PipeTransform } from '@angular/core';

import { DateColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asDateColumn'
})
export class AsDateColumnPipe implements PipeTransform {
  transform(column: ColumnLike): DateColumn | false {
    if (column.type === ColumnType.Date) return column as DateColumn;
    return false;
  }
}
