import { ID } from '@datorama/akita';

import { ActionStep } from '@component/form-layout/form-task-list';
import { BaseModel } from '@state/base';
import { User as UserModel } from '@state/user';

export enum AssignableType {
  Entity = 'entity',
  Investment = 'investment',
  InvestmentAccount = 'investment_account',
  User = 'user',
  Vehicle = 'vehicle'
}

export enum PageObjectType {
  Checkbox = 'checkbox',
  CheckboxSpecial = 'checkbox_special',
  Date = 'date',
  FixedText = 'fixed_text',
  Signature = 'signature',
  Text = 'text'
}

export enum SelectableType {
  Document = 'document',
  Element = 'element',
  Form = 'form',
  Mapper = 'mapper',
  Section = 'section',
  PageObject = 'pageObject',
  Rtd = 'rtd'
}

export enum VisibilityValue {
  Always = 'always',
  Entity = 'entity',
  EntityTrust = 'entity-trust',
  Individual = 'individual',
  IndividualJoint = 'individual-joint',
  Joint = 'joint',
  Trust = 'trust'
}

export enum FormDisplayType {
  Single = 'single',
  Wizard = 'wizard'
}

export enum SigningActorRoleType {
  Counter1 = 'counter_1',
  Counter2 = 'counter_2',
  Counter3 = 'counter_3',
  Counter4 = 'counter_4',
  Counter5 = 'counter_5',
  Counter6 = 'counter_6',
  Primary1 = 'primary_1',
  Primary2 = 'primary_2',
  Primary3 = 'primary_3',
  Primary4 = 'primary_4',
  Primary5 = 'primary_5',
  Primary6 = 'primary_6'
}

export enum SectionType {
  Hidden = 'Form/Section/Hidden',
  InvestmentAccountSelection = 'Form/Section/InvestmentAccountSelection',
  InvestmentAccountSummary = 'Form/Section/InvestmentAccountSummary',
  Investors = 'Form/Section/Investors',
  ProviderAccessRequest = 'Form/Section/ProviderAccessRequest',
  Team = 'Fom/Section/Team',
  Standard = 'Form/Section'
}

export interface ElementObject {
  counter_signable: boolean;
  custom_variable_data_type: string;
  custom_variable_id: ID;
  custom_variable_key: string;
  data_type: string;
  default_value: any;
  description?: string;
  field_options: FormFieldOption[];
  forms?: Form[];
  id: ID;
  global?: boolean;
  label: string;
  page_id: ID;
  page_object_id: ID;
  parsed_raw_html?: string;
  raw_html?: string;
  read_only: boolean;
  required: boolean;
  title?: string;
  tooltip?: string;
  widget_type: WidgetType;
}

export interface FormSection extends ActionStep {
  conditionalVisibility?: VisibilityValue;
  content?: ElementObject;
  cta_label?: string;
  elements: FormElement[];
  form_id: ID;
  id: string;
  position: number;
  selectableType: SelectableType.Section;
  subtitle?: string;
  title: string;
  type: SectionType;
  visibility_key?: string;
  visibility_values: Array<string>;
}

export enum WidgetType {
  Checkbox = 'checkbox',
  Content = 'content',
  Custom = 'custom',
  DatePicker = 'date_picker',
  Document = 'document',
  FixedText = 'fixed_text', // maybe
  Hidden = 'hidden',
  MultiSelect = 'multi_select',
  NumberInput = 'number_input',
  Password = 'password',
  Radio = 'radio',
  Signature = 'signature', // maybe
  SlideToggle = 'slide-toggle',
  TextArea = 'text_area',
  TextInput = 'text_input'
}

export enum ElementObjectType {
  Content = 'content',
  CounterSignable = 'counter_signable',
  Form = 'form',
  FormField = 'form/field',
  Group = 'group',
  Primary = 'primary'
}

export interface FormElement {
  conditionalVisibility?: VisibilityValue;
  configuration: FormElementConfiguration;
  dynamically_resize_to_fit?: string;
  element_object:
    | SingleCheckbox
    | FixedText
    | DateLabel
    | Signature
    | TextBox
    | TrueFalseGroup
    | CustomVariable;
  element_object_type: ElementObjectType;
  form_section_id: ID;
  id: ID;
  internal_notes?: string;
  isSubmitting?: boolean;
  position: number;
  role: SigningActorRoleType;
  selectableType: SelectableType;
  stamp_external_user_id?: string;
  type?: SelectableType.Element; // might need to move out of this interface
  visibility_key?: string;
  visibility_values: Array<string>;
}

interface FormElementConfiguration {
  accept?: string;
  autocomplete?: string;
  checkboxSpecialId?: string;
  checkboxSpecialValue?: string;
  direction?: string;
  elementClass?: string;
  groupId?: ID;
  letterSpacing?: string;
  maskFunction?: any; // TODO: Find signature
  placeholder?: string;
  questionLocation?: string;
  richEditor?: string;
  transformPattern?: string;
  transformReplacement?: string;
  transformTrim?: string;
}

interface SingleCheckbox extends ElementObject {
  data_type: ElementObjectDataType.Boolean;
  widget_type: WidgetType.Checkbox;
}

// Note: After placing the page object, the GET request that updates the elements returns data_type
// and width_type as fixed_text instead of date, not sure if it's intentional or a bug
interface DateLabel extends ElementObject {
  data_type: ElementObjectDataType.FixedText;
  widget_type: WidgetType.FixedText;
}

interface FixedText extends ElementObject {
  data_type: ElementObjectDataType.FixedText;
  widget_type: WidgetType.FixedText;
}

interface Signature extends ElementObject {
  data_type: ElementObjectDataType.Signature;
  widget_type: WidgetType.Signature;
}

interface TextBox extends ElementObject {
  data_type: ElementObjectDataType.Text;
  widget_type: WidgetType.TextInput;
}

interface TrueFalseGroup extends ElementObject {
  data_type: ElementObjectDataType.Boolean;
  widget_type: WidgetType.Checkbox;
}

interface CustomVariable extends ElementObject {
  data_type: ElementObjectDataType.Enumeration;
  widget_type: WidgetType.Radio;
}

export enum VisibilityKey {
  InvestorType = 'INVESTOR.TYPE'
}

export enum ElementObjectDataType {
  Boolean = 'boolean',
  Enumeration = 'enumeration',
  FixedText = 'fixed_text',
  Signature = 'signature',
  Text = 'text'
}

export interface FormFieldOption {
  form_field_id?: string;
  id: ID;
  isEditing?: boolean;
  key?: string;
  position?: number;
  raw_html: string;
  value: string;
}

export interface FormVariable {
  id: string;
  assignable_to: string;
  description: string;
  key: string;
  label: string;
  type: string;
  obfuscated: boolean;
  organization_id: string;
}

export interface Form extends BaseModel {
  active: boolean;
  assignable_to: AssignableType;
  counter_signable: boolean;
  description: string;
  display_type: FormDisplayType;
  document: Record<string, unknown>; // TODO: Flesh out document properties
  document_id: ID;
  field_roles: SigningActorRoleType[];
  global: boolean;
  has_invalid_fields: boolean;
  internal: boolean;
  last_unlocked_at: Date;
  last_unlocked_by_user: Partial<UserModel>;
  organization: {
    entity_id: ID;
    id?: ID;
    name: string;
    url_slug?: string;
  };
  organization_id: ID;
  printable: boolean;
  reassignable: boolean;
  rtd: boolean;
  sections: FormSection[];
  selectableType: SelectableType.Form;
  title: string;
  unlocked_at: Date;
  unlocked_by_user: Partial<UserModel>;
  vehicle: {
    entity_id: ID;
    id: ID;
    is_template: boolean;
    name: string;
    type: string; // TODO: Create enum for types
  };
  vehicle_id: ID;
}

export interface MemberOnline {
  email: string;
  name?: string;
}
