import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';

import { BadgeComponent } from './badge.component';

@NgModule({
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
  imports: [CommonModule, InlineSVGModule]
})
export class BadgeModule {}
