import { Pipe, PipeTransform } from '@angular/core';

import { ColumnLike, ColumnType, OwnerColumn } from '../../collection-table.model';

@Pipe({
  name: 'asOwnerColumn'
})
export class AsOwnerColumnPipe implements PipeTransform {
  transform(column: ColumnLike): OwnerColumn | false {
    if (column.type === ColumnType.Owner) return column as OwnerColumn;
    return false;
  }
}
