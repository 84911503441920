import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import zxcvbn, { ZXCVBNScore } from 'zxcvbn';

export interface ValidationResult {
  [key: string]: boolean | ZXCVBNScore;
}

export class PasswordValidator {
  static strength(control: UntypedFormControl): ValidationResult {
    let MIN_PASSWORD_STRENGTH: ZXCVBNScore = 2;
    let controlValue: string = control.value;
    return zxcvbn(controlValue).score < MIN_PASSWORD_STRENGTH ? { weak: true } : null;
  }

  static confirm(group: UntypedFormGroup): ValidationResult {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
