import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FlowMaterialModule } from '@app/flow-material.module';
import { NoneModule } from '@pipe/none';

import { AppLayoutComponent } from './app-layout.component';
import { RouteMatchPipe } from './pipe/route-match/route-match.pipe';

@NgModule({
  declarations: [AppLayoutComponent, RouteMatchPipe],
  exports: [AppLayoutComponent],
  imports: [
    CommonModule,
    FlowMaterialModule,
    InlineSVGModule,
    NoneModule,
    RouterModule,
  ]
})
export class AppLayoutModule {}
