import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum BadgeColor {
  Blue = 'blue',
  Grey = 'grey',
  Green = 'green',
  Red = 'red',
  Transparent = 'transparent',
  Yellow = 'yellow'
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-badge',
  styleUrls: ['./badge.component.scss'],
  templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnInit {
  @HostBinding('class')
  @Input()
  color?: BadgeColor;
  @Input() icon?: string;
  @Input() label: string;
  constructor() {}

  ngOnInit(): void {}
}
