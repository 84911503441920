import { EMPTY, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export const SNACK_ERROR_CONFIG: MatSnackBarConfig = {
  panelClass: 'snack-error'
};

export function getErrorMessage(response: HttpErrorResponse): string {
  let message: string;

  if (response.status === 404) {
    message = 'This resource does not exist.';
  } else if (typeof response.error === 'object') {
    let errors;

    if (Array.isArray(response.error.errors)) {
      let errorsArr = response.error.errors;
      errors = errorsArr[0]?.title
        ? errorsArr.map((error) => error && (error.title as string)).join('\n')
        : errorsArr.join('\n');
    }

    message =
      response.error.error_message ||
      response.error.message ||
      response.error.error ||
      errors ||
      response.error.errors;
  } else {
    message = response.message || response.error;
  }

  return message || 'Something went wrong, please try again or contact support';
}

export function getSnackErrorArguments(response: HttpErrorResponse): [string, string, MatSnackBarConfig] {
  let message = getErrorMessage(response);
  return [message, '', SNACK_ERROR_CONFIG];
}

export function standardError(error: HttpErrorResponse): void {
  let { globalRollbar: rollbar, globalSnackBar: snackBar } = (window as any);
  let snackErrorArgs = getSnackErrorArguments(error);

  rollbar?.error(snackErrorArgs[0], error);
  snackBar?.open(...snackErrorArgs);
}

export function standardError$(error: HttpErrorResponse): Observable<never> {
  standardError(error);
  return EMPTY;
}

// These two functions are similar but created at different times for different use cases
// The latter `resolverError$` is the one we should use going forward because
// it captures more context about where the error originates
export function handleResolverError$(error: HttpErrorResponse, router: Router): Observable<never> {
  let { globalRollbar: rollbar } = (window as any);

  rollbar?.error(`Could not resolve entering route`, error);
  void router.navigate(['/404']);

  return EMPTY;
}

export function resolverError$(error: HttpErrorResponse, type: string, url: string): Observable<never> {
  let { globalRollbar: rollbar, globalRouter: router } = (window as any);

  rollbar?.error(`Could not resolve ${type} at ${url}`, error);
  void router?.navigate(['/404']);

  return EMPTY;
}
