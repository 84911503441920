export function convertCurrencyToNumber(value: string): number {
  if (!value) return 0;
  let num = +value.replace(/[,$]/g, '');
  if (isNaN(num)) return 0;
  return num;
}

export function convertDollarToCents(value: string): number {
  let dollarAmount = convertCurrencyToNumber(value);
  return +(dollarAmount * 100).toFixed(0);
}

export function formatCurrency(value: string | number, decimals?: number): string {
  if (value == null) return;
  if (typeof value === 'string') value = parseFloat(value);
  if (isNaN(value)) return;

  return value.toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: decimals ?? 2,
    style: 'currency'
  });
}

export function dashifyIfCurrencyValueIsZero(value: string): string {
  if (convertCurrencyToNumber(value) === 0) return '–';
  return formatCurrency(value);
}

export function formatNumber(value: number | string): string {
  if (!value) return;
  if (typeof value === 'string') value = parseFloat(value);
  return value.toLocaleString();
}

export function normalizeNumber(value: number | string): number {
  if (!value) return NaN;
  if (typeof value === 'string') return Number(value.replace(/[^0-9.-]/g, ''));
  return value;
}

export function formatToDecimal(value: number | string, decimals: number): string {
  if (!value) return;
  if (typeof value === 'string') value = parseFloat(value);
  return parseFloat(value.toFixed(decimals)).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
}
