import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asRouterLinkValue'
})
export class AsRouterLinkValuePipe implements PipeTransform {
  transform(row: any, value: any): string | string[] {
    if (typeof value === 'function') return value(row) as string | string[];
    return row[value] as string | string[];
  }
}
