import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnyPipe } from './any.pipe';

@NgModule({
  declarations: [AnyPipe],
  exports: [AnyPipe],
  imports: [CommonModule]
})
export class AnyModule {}
