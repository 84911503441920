import { Pipe, PipeTransform } from '@angular/core';

import { ColumnBooleanFunction } from '../../collection-table.model';

@Pipe({
  name: 'asBooleanValue'
})
export class AsBooleanValuePipe implements PipeTransform {
  transform(row: any, value: ColumnBooleanFunction | boolean | string): boolean {
    if (typeof value === 'function') return value(row);
    if (typeof value === 'string' && typeof row[value] === 'boolean') return row[value] as boolean;
    if (typeof value === 'boolean') return value;

    return false;
  }
}
