<div mat-dialog-content>
  <mat-form-field appearance="fill" floatLabel="always">
    <mat-label>Type your name</mat-label>
    <input
      [(ngModel)]="signatureText"
      [required]="true"
      autocomplete="off"
      cdkFocusInitial
      class="signature-input"
      matInput
      name="signature-input"
      spellcheck="false"
    />
  </mat-form-field>
</div>

<p>
  By clicking "Accept &amp; Sign," you agree that you are signing this document
  electronically pursuant to our <a href="{{ TERMS_OF_USE_URL }}" target="_blank">Terms of Use</a>.
</p>

<div class="dialog-actions">
  <button class="close" (click)="onNoClick()">Close</button>
  <button class="accept" [mat-dialog-close]="signatureText" [disabled]="isSignatureInvalid">
    Accept &amp; Sign
  </button>
</div>
