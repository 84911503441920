import { Pipe, PipeTransform } from '@angular/core';

import { IconLinkMetaColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asIconLinkMetaColumn'
})
export class AsIconLinkMetaColumnPipe implements PipeTransform {
  transform(column: ColumnLike): IconLinkMetaColumn | false {
    if (column.type === ColumnType.IconLinkMeta) return column as IconLinkMetaColumn;
    return false;
  }
}
