import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService extends BaseService {
  content: any;

  constructor(private http: HttpClient) {
    super();
  }

  getContents(organizationId: ID, q: string, callbacks?: any): void {
    let url = `${this.BASE_URL}/v1/contents/search`;

    this.http.post(url, { organization_id: organizationId, q }).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }
}
