import { ActivatedRouteSnapshot } from '@angular/router';
import { ID } from '@datorama/akita';

export interface URLInfo {
  browser;
  isCurrentHost: boolean;
  url: URL;
}

interface QueryParamObject {
  [key: string]: QueryParamObject | string | ID;
}
export function getPathInfo(urlString: string): URLInfo {
  let browser = window as any;
  let url = new URL(urlString);
  let isCurrentHost = url.hostname === browser.location.hostname;

  return { browser, isCurrentHost, url };
}

export function getQueryParamsObject(paramsString: string): QueryParamObject {
  let search = paramsString.substring(1);
  let decodedSearchString = decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"');
  return JSON.parse(`{"${decodedSearchString}"}`) as QueryParamObject;
}

export function getRoutePrefixForSnapshot(route: ActivatedRouteSnapshot): string {
  let { membership_entity_id, provider_entity_id } = route.params;

  if (provider_entity_id) return `/p/${provider_entity_id}/e/${membership_entity_id}`;
  if (membership_entity_id) return `/e/${membership_entity_id}`;
  return '';
}
