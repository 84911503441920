import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAnyPipe } from './has-any.pipe';

@NgModule({
  declarations: [HasAnyPipe],
  exports: [HasAnyPipe],
  imports: [CommonModule]
})
export class HasAnyModule {}
