import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { AuthLayoutRepository } from '@state/auth-layout';
import { Invitation, InvitationQuery, InvitationService } from '@state/invitation';
import { MessageBoxType } from '@component/message-box';
import { SessionQuery, SessionService } from '@state/session';
import { SignupView } from '@app/route/signup/signup.component';
import { ZendeskService } from '@state/zendesk';

enum ValidationStatus {
  PendingValidation,
  Valid,
  Invalid
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  MessageBoxType = MessageBoxType;
  private redirectUrl: string;

  authMessage$ = this.authLayoutRepository.authMessage$;
  emailValidationStatus: ValidationStatus = ValidationStatus.PendingValidation;
  loginFormSubmitted$: Observable<boolean>;
  accountLocked$: Observable<boolean>;
  loginSuccess$: Observable<boolean>;
  mfa_token_view$: Observable<boolean>;

  hasTargetInvestment: boolean;
  SignupView = SignupView;

  constructor(
    private authLayoutRepository: AuthLayoutRepository,
    private invitationQuery: InvitationQuery,
    private invitationService: InvitationService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private titleService: Title,
    public sessionQuery: SessionQuery,
    public sessionService: SessionService,
    public zendeskService: ZendeskService
  ) {}

  ngOnInit() {
    this.accountLocked$ = this.sessionQuery.select('accountLocked');
    this.loginFormSubmitted$ = this.sessionQuery.select('loginFormSubmitted');
    this.loginSuccess$ = this.sessionQuery.select('loginSuccess');
    this.mfa_token_view$ = this.sessionQuery.select('mfa_token_view');

    this.titleService.setTitle('Login | Flow');

    this.checkForTargetInvestment();
    void this.checkForInvitation().then((invitation?: Invitation) => {
      let email = invitation?.email;
      this.sessionService.reset(email);
      if (invitation) {
        this.redirectUrl = invitation.redirect_url;
      } else {
        this.redirectUrl = this.route.snapshot.queryParams.redirectUrl;
      }
    });

    this.ngZone.runOutsideAngular(() => {
      this.zendeskService.toggleZendeskWidgetDisplay();
    });
  }

  ngOnDestroy() {
    this.zendeskService.toggleZendeskWidgetDisplay();
  }

  ngAfterViewInit(): void {
    let elName = '#email-input';
    let el: HTMLElement = document.querySelector(elName);
    if (el) setTimeout(() => el.focus());
  }

  get isEmailValid(): boolean {
    return this.emailValidationStatus === ValidationStatus.Valid;
  }

  get isEmailInvalid(): boolean {
    return this.emailValidationStatus === ValidationStatus.Invalid;
  }

  checkForInvitation(): Promise<Invitation | void> {
    let token: string = this.route.snapshot.queryParams.token;

    return new Promise((resolve) => {
      let invitation = this.invitationQuery.getValue().invitation;
      if (invitation) {
        resolve(invitation);
      } else if (token) {
        this.invitationService.peek$(token).subscribe({
          next: (invitationObject: Invitation) => {
            resolve(invitationObject);
          }
        });
      } else {
        resolve();
      }
    });
  }

  checkForTargetInvestment(): void {
    let redirectUrl: string = this.route.snapshot.queryParams.redirectUrl;
    if (!redirectUrl) return;

    let investmentUrlMatch =
      /\/investment\/[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}/;
    this.hasTargetInvestment = investmentUrlMatch.test(redirectUrl);
  }

  validateEmailField(): void {
    let { email } = this.sessionService.credentials;
    if (this.sessionService.testEmail(email)) {
      this.emailValidationStatus = ValidationStatus.Invalid;
    } else {
      this.emailValidationStatus = ValidationStatus.Valid;
    }
  }

  resetEmailValidation(): void {
    this.validateEmailField();
    if (this.isEmailValid) return;
    this.emailValidationStatus = ValidationStatus.PendingValidation;
  }

  isBtnDisabled(): boolean {
    let isEmpty = !this.sessionService.credentials.email || !this.sessionService.credentials.password;
    return this.isEmailInvalid || isEmpty;
  }

  submit(skipForgotPasswordRedirect = false): void {
    this.validateEmailField();
    let { isEmailInvalid, redirectUrl } = this;

    if (isEmailInvalid) return this.ngAfterViewInit();

    this.sessionService
      .submitLogin$({
        errorCallback: () => {
          setTimeout(() => {
            let el: HTMLInputElement = document.querySelector('#password-input');
            if (el) {
              el.value = '';
              el.focus();
              this.sessionService.credentials.password = '';
            }
          });
        },
        redirectUrl,
        skipForgotPasswordRedirect
      })
      .subscribe();
  }

  submitOtp(): void {
    this.sessionService.submitLoginOtp({
      errorCallback: () => {
        setTimeout(() => {
          let el: HTMLInputElement = document.querySelector('.otp-input');
          if (el) {
            el.value = '';
            el.focus();
          }
        });
      },
      redirectUrl: this.redirectUrl
    });
  }
}
