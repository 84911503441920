import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { mergeMap, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  DocumentService as StateDocumentService,
  DocumentUploadPreview,
  Page,
  PageObject,
  SignUploadDocument
} from '@state/document';
import {
  DocumentFieldDeletionState,
  DocumentFieldDeletionStatus,
  FormBuilderDocument
} from '@app/shared/services/document.model';
import { DocumentVersionsService } from '@state/document-versions';
import { ElementObject } from '@state/form';
import { environment } from '@env/environment';

import { BaseService } from './base.service';
import { EditorState } from './editor.state';
import { FormService } from './form.service';
import { MapperService } from './mapper.service';


export interface SignUploadRequestBody {
  filename: string;
  mimetype: string;
  size: number;
  source_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends BaseService {
  ASSIGNABLE_ICONS = {
    entity: 'business',
    investment: 'account_balance_wallet',
    investment_account: 'account_balance_wallet',
    user: 'account_circle',
    vehicle: 'account_balance'
  };
  ASSIGNABLE_TITLES = {
    entity: 'Entities',
    investment: 'Investment',
    investment_account: 'Investor Accounts',
    user: 'Users',
    vehicle: 'Funds/SPVs'
  };
  deletionState: DocumentFieldDeletionState = [DocumentFieldDeletionStatus.NotDeleting, ''];
  document: FormBuilderDocument;
  fieldButtonSelected: string;
  inputSelected: boolean;
  mapperObjects: any = {};
  request: any;

  constructor(
    private stateDocumentService: StateDocumentService,
    private http: HttpClient,
    private documentVersionsService: DocumentVersionsService,
    private mapperService: MapperService,
    public formService: FormService,
    public state: EditorState
  ) {
    super();
  }

  setElementObject = (eo: ElementObject): void => {
    let page = this.document.pages.find((documentPage) => documentPage.id === eo.page_id);
    if (!page) return;

    let ppo = page.page_objects.find((pageObject) => pageObject.id === eo.page_object_id);
    ppo.field_label = eo.label;
  };

  setPageObject = (po: PageObject): void => {
    let pageId = po.document_page_id || this.state.selectedItemPageObject?.document_page_id;
    let page = this.document.pages.find((documentPage) => documentPage.id === pageId);

    if (!page) return;

    let ppo = page.page_objects.find((pageObject) => pageObject.id === po.id);
    ppo.left = po.left;
    ppo.top = po.top;
    ppo.width = po.width;
  };

  getDocument$(id: ID, callbacks?: any): Subscription {
    let url = `${environment.apiUrl}/documents/${id}`;

    return this.http.get<FormBuilderDocument>(url).subscribe({
      error: (error: HttpErrorResponse) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (document) => {
        this.document = document;
        this.getDocumentVersions();
        this.applyCallback('success', callbacks, document);
      }
    });
  }

  getDocumentVersions(): void {
    this.documentVersionsService.getDocumentVersions$(this.document.id).subscribe();
  }

  signUpload(document: any, callbacks?: any): Subscription {
    return this.http.post(environment.apiUrl + '/v1/documents/sign_upload', document).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  // TODO: this should be on the newer v1 document service and not the old shared one
  signUploadedDocument$(documents: {
    documents: SignUploadRequestBody[];
  }): Observable<DocumentUploadPreview> {
    return this.http
      .post<SignUploadDocument[]>(environment.apiUrl + '/v1/documents/sign_upload', documents)
      .pipe(
        mergeMap((data) => {
          let [uploadedDocument] = data;
          return this.stateDocumentService.getPreview$(uploadedDocument.id);
        })
      );
  }

  createPageObject$(pageObject: Partial<PageObject>): Observable<PageObject[]> {
    let url = `${environment.apiUrl}/v1/documents/${pageObject.document_id}/page_objects`;
    pageObject['organization_id'] = this.document.organization_id;

    return this.http.post<PageObject[]>(url, pageObject);
  }

  deletePageObject$(documentId: ID, pageObjectId: ID): Observable<null> {
    let url = `${environment.apiUrl}/v1/documents/${documentId}/page_objects/${pageObjectId}`;

    return this.http.delete<null>(url);
  }

  getDownloadToken(documentId: ID): Subscription {
    return this.http.get(`${environment.apiUrl}/documents/${documentId}/download_url`).subscribe({
      next: (data: any) => {
        window.location.href = data.download_token;
      }
    });
  }

  getPageIndex(pageId: ID): number {
    return this.document.pages.findIndex((page) => page.id === pageId);
  }

  getPageObjectIndex(page: Partial<Page>, pageObject: PageObject): number {
    return page.page_objects.findIndex((po) => po.id === pageObject.id);
  }

  movePageObject(pageObject: PageObject, destinationPageId: ID, sourcePageId: ID): void {
    let destinationPageIndex = this.getPageIndex(destinationPageId);
    let sourcePageIndex = this.getPageIndex(sourcePageId);

    let sourcePageObjectIndex = this.getPageObjectIndex(this.document.pages[sourcePageIndex], pageObject);
    this.document.pages[sourcePageIndex].page_objects.splice(sourcePageObjectIndex, 1);

    this.document.pages[destinationPageIndex].page_objects.push(pageObject);

    this.mapperService.moveFieldForPageObject(pageObject, sourcePageId, destinationPageId);
  }

  setElementObjectLabel(field: ElementObject): void {
    if (field.page_object_id) {
      this.mapperObjects[field.page_object_id].label = field.label;
      this.setElementObject(field);
    }
  }

  updateAndSetPageObject$(
    documentId: ID,
    pageObjectId: ID,
    params: Partial<PageObject>
  ): Observable<PageObject> {
    return this.updatePageObject$(documentId, pageObjectId, params).pipe(tap({ next: this.setPageObject }));
  }

  updateDeletionState(deletionState: DocumentFieldDeletionState): void {
    this.deletionState = deletionState;
  }

  updatePageObject$(documentId: ID, pageObjectId: ID, params: Partial<PageObject>): Observable<PageObject> {
    let url = `${environment.apiUrl}/v1/documents/${documentId}/page_objects/${pageObjectId}`;
    return this.http.put<PageObject>(url, params);
  }
}
