import { Pipe, PipeTransform } from '@angular/core';

import { TooltipColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asTooltipColumn'
})
export class AsTooltipColumnPipe implements PipeTransform {
  transform(column: ColumnLike): TooltipColumn | false {
    if (column.type === ColumnType.Tooltip) return column as TooltipColumn;
    return false;
  }
}
