import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class InvestmentAccountService {
  constructor(private http: HttpClient) {}

  // TODO: Find actual type and reflect in redirect guard
  get$(id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v1/investment_accounts/${id}`);
  }
}
