import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { Control, FormLayoutRepository } from '@state/form-layout';
import { MembershipsQuery, MembershipsService } from '@state/memberships';
import { SessionQuery } from '@state/session';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-blank-layout',
  styleUrls: ['./blank-layout.component.scss'],
  templateUrl: './blank-layout.component.html'
})
export class BlankLayoutComponent implements OnInit {
  membershipLoading$: Observable<boolean>;
  title$: Observable<string>;
  controls$: Observable<Control[]>;

  constructor(
    private formLayoutRepository: FormLayoutRepository,
    private membershipsQuery: MembershipsQuery,
    private membershipsService: MembershipsService,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit() {
    this.controls$ = this.formLayoutRepository.controls$;
    this.title$ = this.formLayoutRepository.title$;

    this.membershipLoading$ = this.membershipsQuery.selectLoading();

    if (this.loggedIn) this.membershipsService.getMembershipByRoute();
  }

  get loggedIn(): boolean {
    return this.sessionQuery.loggedIn();
  }
}
