import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asStringValue'
})
export class AsStringValuePipe implements PipeTransform {
  transform(row: any, value: any): string {
    if (typeof value === 'function') return value(row) as string;
    if (row[value] === null) return null;
    if (row[value]) return row[value] as string;
    return value as string;
  }
}
