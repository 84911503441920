import { Pipe, PipeTransform } from '@angular/core';

import { RowSettings } from '../../collection-table.model';

@Pipe({
  name: 'getRowClass',
  pure: false
})
export class GetRowClassPipe implements PipeTransform {
  transform(
    row: any,
    bulkSelectable: (obj: any) => boolean = () => true,
    selectable?: boolean,
    rowSettings?: RowSettings
  ): string {
    let classes = ['mat-row'];
    if ((bulkSelectable && bulkSelectable(row)) || selectable) classes.push('can-select');
    if (rowSettings?.active && rowSettings.active(row)) classes.push('active');
    if (rowSettings?.class && rowSettings.class(row)) classes.push(rowSettings.class(row));

    return classes.join(' ');
  }
}
