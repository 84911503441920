<ng-container *ngIf="
  (membershipLoading$ | async | none) &&
  {
    activeMembership: activeMembership$ | async,
    membershipEntity: membershipEntity$ | async,
    mobileMenuActive: mobileMenuActive$ | async,
    sidenavCollapsed: sidenavCollapsed$ | async,
    user: user$ | async,
    userSidenavInfo: userSidenavInfo$ | async,
    isLoggedAsProvider: isLoggedAsProvider$ | async,
    enableReportsForProviders: enableReportsForProviders$ | async
  } as vm"
>
  <header
    *ngIf="vm.user"
    [class.active]="vm.mobileMenuActive"
    [class.collapsed]="vm.sidenavCollapsed"
    [class.provider-view]="isProviderInRoute"
  >
    <button
      (click)="toggleSidenav()"
      [inlineSVG]="
        vm.sidenavCollapsed
        ? '/assets/icons/chevron-right--end.svg'
        : '/assets/icons/chevron-left--start.svg'
      "
      aria-label="toggle sidenav"
    ></button>

    <div class="account-switcher" [class.active]="accountSwitcherVisible">
      <div
        (menuClosed)="accountSwitcherVisible = false"
        (menuOpened)="accountSwitcherVisible = true"
        [matMenuTriggerFor]="appMenu"
        class="user-info"
      >
        <ng-container *ngIf="vm.activeMembership; else noProfile">
          <img
            *ngIf="vm.activeMembership.logo_url; else noProfileImage"
            [src]="vm.activeMembership.logo_url"
            alt="user logo"
            class="user-image"
          />
          <ng-template #noProfileImage>
            <span class="user-initials" aria-hidden="true">{{ vm.activeMembership.initials }}</span>
          </ng-template>
          <div class="membership-name-info">
            <div class="tag provider" *ngIf="isProvider">Provider</div>
            <div class="tag pro" *ngIf="showProBadge">pro</div>
            <span class="user-name">
              {{ vm.activeMembership.entity_id ? vm.activeMembership.name : "All Accounts" }}
            </span>
          </div>
        </ng-container>
        <ng-template #noProfile>
          <span
            *ngIf="vm.userSidenavInfo"
            class="user-initials"
            aria-hidden="true"
            >{{ vm.userSidenavInfo.userInitials }}</span
          >
          <span class="user-name">{{ vm.user.full_name }}</span>
        </ng-template>
        <div class="caret" aria-hidden="true">
          <span class="left"></span>
          <span class="right"></span>
        </div>
      </div>
      <mat-menu #appMenu="matMenu">
        <a mat-menu-item class="border-link" routerLink="/switch-profile">Switch Account</a>
        <hr />
        <a mat-menu-item class="border-item" [routerLink]="[routePrefix, 'settings']">Settings</a>
        <button mat-menu-item (click)="logout()">Log Out</button>
      </mat-menu>
    </div>

    <hr />

    <nav>
      <a
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'entities'"
        [matTooltip]="vm.sidenavCollapsed ? 'Organization' : null"
        [routerLink]="[routePrefix, 'entities']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span [inlineSVG]="'/assets/icons/vector.svg'"></span>
        <span>Organization</span>
      </a>
      <a
        *ngIf="isProvider"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'client'"
        [matTooltip]="vm.sidenavCollapsed ? 'Clients' : null"
        [routerLink]="[routePrefix, 'clients']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span [inlineSVG]="'/assets/icons/suitcase.svg'"></span>
        <span>Clients</span>
      </a>

      <a
        *ngIf="!isProvider && onProviderView && vm.activeMembership.full_cpc_on_root"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'investment'"
        [matTooltip]="vm.sidenavCollapsed ? 'Investments' : null"
        [routerLink]="[routePrefix, 'investments']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span [inlineSVG]="'/assets/icons/suitcase.svg'"></span>
        <span>Investments</span>
      </a>

      <a
        *ngIf="!onProviderView && !isProvider"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'investment'"
        [matTooltip]="vm.sidenavCollapsed ? 'Investments' : null"
        [routerLink]="[routePrefix, 'investments']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span [inlineSVG]="'/assets/icons/suitcase.svg'"></span>
        <span>Investments</span>
      </a>
      <a
        *ngIf="!isProvider && (vm.user.organization_member || vm.user.vehicle_member)"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'fund'"
        [matTooltip]="vm.sidenavCollapsed ? 'Funds' : null"
        [routerLink]="[routePrefix, 'funds']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span [inlineSVG]="'/assets/icons/shield.svg'"></span>
        <span>Funds</span>
      </a>
      <a
        *ngIf="
          !onProviderView
          && !isProvider
          && (vm.user.organization_member || vm.user.admin)
          && (!routePrefix || (isOrganization && vm.membershipEntity.direct_organization.allow_data_room))
        "
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'data'"
        [matTooltip]="vm.sidenavCollapsed ? 'Data Rooms' : null"
        [routerLink]="[routePrefix, 'data-rooms']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/cards.svg"></span>
        <span>Data Rooms</span>
      </a>

      <a
        *ngIf="!isProvider && onProviderView && vm.activeMembership.full_cpc_on_root"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'document'"
        [matTooltip]="vm.sidenavCollapsed ? 'Documents' : null"
        [routerLink]="[routePrefix, 'documents']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/document.svg"></span>
        <span>Documents</span>
      </a>

      <a
        *ngIf="!onProviderView && !isProvider"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'document'"
        [matTooltip]="vm.sidenavCollapsed ? 'Documents' : null"
        [routerLink]="[routePrefix, 'documents']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/document.svg"></span>
        <span>Documents</span>
      </a>
      <a
        *ngIf="!isProvider && onProviderView && vm.user.organization_member && vm.activeMembership.full_cpc_on_root"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'contact'"
        [matTooltip]="vm.sidenavCollapsed ? 'Contacts' : null"
        [routerLink]="[routePrefix, 'contacts']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/user.svg?ver=2"></span>
        <span>Contacts</span>
      </a>

      <a
        *ngIf="!onProviderView && !isProvider && vm.user.organization_member"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'contact'"
        [matTooltip]="vm.sidenavCollapsed ? 'Contacts' : null"
        [routerLink]="[routePrefix, 'contacts']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/user.svg?ver=2"></span>
        <span>Contacts</span>
      </a>
      <a
        *ngIf="!isProvider && (!vm.isLoggedAsProvider || vm.enableReportsForProviders) && vm.user.organization_member && (!routePrefix || (routePrefix && activeMembership.organization_id))"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'reports'"
        [matTooltip]="vm.sidenavCollapsed ? 'Reports' : null"
        [routerLink]="[routePrefix, 'reports']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/table.svg"></span>
        <span>Reports</span>
      </a>
      <a
        *ngIf="isOrganization && isServiceEnabled && !isProvider && !isProviderInRoute"
        (click)="toggleMobileMenu()"
        [class.active]="router.url | routeMatch: 'workflow'"
        [matTooltip]="vm.sidenavCollapsed ? 'Flows' : null"
        [routerLink]="[routePrefix, 'workflows']"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
      >
        <span inlineSVG="/assets/icons/cards-2.svg"></span>
        <span>Flows</span>
      </a>

      <hr />

      <a
        [matTooltip]="vm.sidenavCollapsed ? 'Help' : null"
        href="https://help.flowinc.com"
        matTooltipClass="nav-tooltip right"
        matTooltipPosition="right"
        target="_blank"
      >
        <span inlineSVG="/assets/icons/info-2.svg"></span>
        <span>Help</span>
      </a>
    </nav>

    <a
      [matTooltip]="vm.userSidenavInfo?.userNavInfoToolTip"
      class="current-user-info"
      matTooltipClass="current-user-tooltip right"
      matTooltipPosition="right"
      routerLink="/settings"
    >
      <span>{{ vm.userSidenavInfo?.userInitials }}</span>
      <span>{{ vm.user.full_name }}</span>
      <small>{{ vm.user.email }}</small>
    </a>

    <h1 *ngIf="!isProviderInRoute">
      <a
        (click)="toggleMobileMenu()"
        [inlineSVG]="vm.sidenavCollapsed ? '/assets/flow_white_mark.svg' : '/assets/flow_white.svg'"
        [routerLink]="isProvider ? [routePrefix, 'clients'] : ['/dashboard']"
      >
        Flow
      </a>
    </h1>
    <ng-container *ngIf="vm.activeMembership">
      <img
        alt="provider logo"
        [src]="vm.activeMembership.provider_context.logo"
        class="provider-image"
        *ngIf="vm.activeMembership.provider_context && vm.activeMembership.provider_context.logo"
      />
    </ng-container>
  </header>

  <main (click)="toggleMobileMenu()">
    <router-outlet></router-outlet>
  </main>
</ng-container>
