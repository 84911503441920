import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { Form } from '@state/form';

import { SignatureRequest } from './signature-request.model';

export interface SignatureRequestState {
  currentFormIndex: number;
  form?: Form;
  formValues: Record<string, unknown>;
  signatureRequest?: SignatureRequest;
  submitting: boolean;
  wrongActor: boolean;
}

export function createInitialState(): SignatureRequestState {
  return {
    currentFormIndex: 0,
    form: undefined,
    formValues: {},
    signatureRequest: undefined,
    submitting: false,
    wrongActor: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'signature-request', resettable: true })
export class SignatureRequestStore extends Store<SignatureRequestState> {
  constructor() {
    super(createInitialState());
  }
}
