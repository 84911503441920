import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionPipe } from './function.pipe';

@NgModule({
  declarations: [FunctionPipe],
  exports: [FunctionPipe],
  imports: [CommonModule]
})
export class FunctionPipeModule {}
