import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AppLayoutQuery, AppLayoutService } from '@state/app-layout';
import { Entity } from '@state/entities';
import { Membership, MembershipsQuery, MembershipsService } from '@state/memberships';
import { MembershipEntityRepository } from '@state/membership-entity';
import { SessionService } from '@state/session';
import { User, UserQuery } from '@state/user';
import { LaunchDarklyRepository } from '@state/launch-darkly';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-app-layout',
  styleUrls: ['./app-layout.component.scss'],
  templateUrl: './app-layout.component.html'
})
export class AppLayoutComponent implements OnInit {
  activeMembership$: Observable<Membership>;
  membershipEntity$: Observable<Entity> = this.membershipEntityRepository.membershipEntity$;
  membershipLoading$: Observable<boolean>;
  mobileMenuActive$: Observable<boolean>;
  sidenavCollapsed$: Observable<boolean>;
  user$: Observable<User> = this.userQuery.user$;
  userSidenavInfo$: Observable<{ userNavInfoToolTip: string; userInitials: string }>;
  enableReportsForProviders$: Observable<boolean> = this.launchDarklyRepository.enableReportsForProviders$;
  accountSwitcherVisible = false;
  activeMembership: Membership;
  isLoggedAsProvider$ = this.membershipsQuery.isLoggedAsProvider$;
  onProviderView = !!this.route.snapshot.params.provider_entity_id;
  scope: string = this.route.snapshot.data.scope;

  constructor(
    private appLayoutQuery: AppLayoutQuery,
    private appLayoutService: AppLayoutService,
    private membershipEntityRepository: MembershipEntityRepository,
    private membershipsQuery: MembershipsQuery,
    private membershipsService: MembershipsService,
    private sessionService: SessionService,
    private userQuery: UserQuery,
    private route: ActivatedRoute,
    public router: Router,
    private launchDarklyRepository: LaunchDarklyRepository
  ) {}

  ngOnInit() {
    this.activeMembership$ = this.membershipsQuery.select('membership').pipe(
      tap({
        next: (membership) => {
          this.activeMembership = membership;
        }
      })
    );
    this.membershipLoading$ = this.membershipsQuery.selectLoading();
    this.mobileMenuActive$ = this.appLayoutQuery.select('mobileMenuActive');
    this.sidenavCollapsed$ = this.appLayoutQuery.sidenavCollapsed$;
    this.membershipsService.getMembershipByRoute();
    this.setUserSideNavInfo();
  }

  setUserSideNavInfo(): void {
    this.userSidenavInfo$ = this.user$.pipe(
      filter((user: User) => !!user),
      map(({ first_name, last_name, email }: User) => {
        let firstInitial = first_name ? first_name.charAt(0) : '';
        let lastInitial = last_name ? last_name.charAt(0) : '';
        let userInitials = `${firstInitial}${lastInitial}`;
        let userNavInfoToolTip = `${first_name ? first_name : ''} ${last_name ? last_name : ''}\n${email}`;

        return { userInitials, userNavInfoToolTip };
      })
    );
  }

  get isProvider(): boolean {
    let { activeMembership: membership } = this;
    return !!membership && membership.provider;
  }

  get isProviderInRoute(): boolean {
    return /(\/p\/)([a-zA-Z0-9-]+)/.test(this.router.url);
  }

  get isServiceEnabled(): boolean {
    return this.membershipsQuery.isServiceEnabled;
  }

  get isOrganization(): boolean {
    return this.membershipsQuery.isOrganization;
  }

  get routePrefix(): string {
    return this.sessionService.routePrefix;
  }

  get showProBadge(): boolean {
    let { activeMembership: membership, isOrganization } = this;
    return !!membership && membership.saas_enabled && isOrganization && !membership.provider;
  }

  toggleMobileMenu(): void {
    this.appLayoutService.toggleMobileMenu(false);
  }

  toggleSidenav(): void {
    this.appLayoutService.toggleSidenav();
  }

  logout(): void {
    this.sessionService.logout();
  }
}
