import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BadgeModule } from '@component/badge';
import { FunctionPipeModule } from '@pipe/function';
import { MomentDatePipeModule } from '@pipe/moment-date';
import { NoneModule } from '@pipe/none';
import { TaskButtonModule } from '@component/task-button';

import { AsActionColumnPipe } from './pipes/as-action-column';
import { AsBadgeColorValuePipe } from './pipes/as-badge-color-value/as-badge-color-value.pipe';
import { AsBooleanValuePipe } from './pipes/as-boolean-value/as-boolean-value.pipe';
import { AsCellValuePipe } from './pipes/as-cell-value';
import { AsDateColumnPipe } from './pipes/as-date-column';
import { AsFundInvestorStatusColumnPipe } from './pipes/as-fund-investor-status-column';
import { AsIconBadgeColumnPipe } from './pipes/as-icon-badge-column';
import { AsIconColumnPipe } from './pipes/as-icon-column';
import { AsIconLinkColumnPipe } from './pipes/as-icon-link-column';
import { AsIconLinkMetaColumnPipe } from './pipes/as-icon-link-meta-column';
import { AsIconMetaColumnPipe } from './pipes/as-icon-meta-column';
import { AsImageColumnPipe } from './pipes/as-image-column';
import { AsImageMetaColumnPipe } from './pipes/as-image-meta-column';
import { AsLinkColumnPipe } from './pipes/as-link-column';
import { AsLinkMetaColumnPipe } from './pipes/as-link-meta-column';
import { AsListOfLinksColumnPipe } from './pipes/as-list-of-links-column';
import { AsListOfLinksValuesPipe } from './pipes/as-list-of-links-values';
import { AsMetaColumnPipe } from './pipes/as-meta-column';
import { AsMetaSelectColumnPipe } from './pipes/as-meta-select-column';
import { AsOwnerColumnPipe } from './pipes/as-owner-column/as-owner-column.pipe';
import { AsRouterLinkValuePipe } from './pipes/as-router-link-value';
import { AsSlideToggleColumnPipe } from './pipes/as-slide-toggle-column';
import { AsStatusValuesPipe } from './pipes/as-status-values';
import { AsStringValuePipe } from './pipes/as-string-value';
import { AsTagsColumnPipe } from './pipes/as-tags-column';
import { AsTagsValuePipe } from './pipes/as-tags-value';
import { AsTooltipColumnPipe } from './pipes/as-tooltip-column';
import { AsValueBadgeColumnPipe } from './pipes/as-value-badge-column';
import { CollectionTableComponent } from './collection-table.component';
import { GetRowClassPipe } from './pipes/get-row-class';
import { AsValueTagsColumnPipe } from './pipes/as-value-tags-column';

@NgModule({
  declarations: [
    AsActionColumnPipe,
    AsBadgeColorValuePipe,
    AsBooleanValuePipe,
    AsCellValuePipe,
    AsDateColumnPipe,
    AsFundInvestorStatusColumnPipe,
    AsIconBadgeColumnPipe,
    AsIconColumnPipe,
    AsIconLinkColumnPipe,
    AsIconLinkMetaColumnPipe,
    AsIconMetaColumnPipe,
    AsImageColumnPipe,
    AsImageMetaColumnPipe,
    AsLinkColumnPipe,
    AsLinkMetaColumnPipe,
    AsListOfLinksColumnPipe,
    AsListOfLinksValuesPipe,
    AsMetaColumnPipe,
    AsMetaSelectColumnPipe,
    AsOwnerColumnPipe,
    AsRouterLinkValuePipe,
    AsSlideToggleColumnPipe,
    AsStatusValuesPipe,
    AsStringValuePipe,
    AsTagsColumnPipe,
    AsTagsValuePipe,
    AsTooltipColumnPipe,
    AsValueBadgeColumnPipe,
    AsValueTagsColumnPipe,
    CollectionTableComponent,
    GetRowClassPipe
  ],
  exports: [CollectionTableComponent],
  imports: [
    BadgeModule,
    ClipboardModule,
    CommonModule,
    FunctionPipeModule,
    InlineSVGModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MomentDatePipeModule,
    NoneModule,
    RouterModule,
    TaskButtonModule
  ]
})
export class CollectionTableModule {}
