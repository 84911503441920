import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';

import { Subscription } from 'rxjs';
import { UserQuery } from '@state/user';
import { ZendeskService } from '@state/zendesk';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-four-oh-four',
  styleUrls: ['./four-oh-four.component.scss'],
  templateUrl: './four-oh-four.component.html'
})
export class FourOhFourComponent implements OnInit, OnDestroy {
  isUserLoggedIn$ = this.userQuery.hasFetchedUser$;
  isUserLoggedInSubscription: Subscription;
  isZendeskWidgetHidden = false;
  user$ = this.userQuery.select('user');

  constructor(private ngZone: NgZone, private userQuery: UserQuery, public zendeskService: ZendeskService) {}

  ngOnInit() {
    this.isUserLoggedInSubscription = this.isUserLoggedIn$
      .pipe(
        tap({
          next: (isUserLoggedIn) => {
            if (!isUserLoggedIn) {
              this.isZendeskWidgetHidden = true;
              this.ngZone.runOutsideAngular(() => {
                this.zendeskService.toggleZendeskWidgetDisplay();
              });
            }
          }
        }),
        finalize(() => {
          if (this.isZendeskWidgetHidden) this.zendeskService.toggleZendeskWidgetDisplay();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.isUserLoggedInSubscription.unsubscribe();
  }
}
