import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { ApplicationService } from '@state/application';
import { OrganizationService, PortfolioReport } from '@state/organization';

@Injectable({
  providedIn: 'root'
})
export class PortfolioReportResolver  {
  constructor(
    private applicationService: ApplicationService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PortfolioReport> {
    let { organization_id, contact_id } = route.queryParams;

    this.applicationService.pushLoading('portfolio-report');

    if (!organization_id || !contact_id) this.handleError$();

    return this.organizationService.getAndStorePortfolioReport$(organization_id as ID, contact_id as ID).pipe(
      catchError(this.handleError$),
      finalize(() => {
        this.applicationService.popLoading('portfolio-report');
      })
    );
  }

  handleError$ = (error?: HttpErrorResponse): Observable<never> => {
    if (error) console.error(error);
    void this.router.navigate(['/404']);
    return EMPTY;
  };
}
