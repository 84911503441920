import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

// TODO: This would more appropriately be part of the standard form state
import { Control, ControlStyle } from '@state/form-layout';
import { SessionService } from '@state/session';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-blank-layout-container',
  styleUrls: ['./blank-layout-container.component.scss'],
  templateUrl: './blank-layout-container.component.html'
})
export class BlankLayoutContainerComponent implements OnInit {
  @Input() title$: Observable<string>;
  @Input() controls$: Observable<Control[]>;

  ControlStyle = ControlStyle;

  constructor(private sessionService: SessionService) {}

  ngOnInit() {}

  get routePrefix(): string {
    return this.sessionService.routePrefix;
  }

  controlButtonTestId(controlLabel: string): string {
    return `blank-layout-${controlLabel.toLowerCase().replace(/ /g, '-')}-btn`;
  }
}
