import { Injectable } from '@angular/core';
import { ID, Store, StoreConfig } from '@datorama/akita';

export interface Invitation {
  committed_at?: string;
  committed_at_formatted?: string;
  email: string;
  first_name: string;
  id: ID;
  is_user: boolean;
  last_name: string;
  member_emails: string[];
  name: string;
  needs_accreditation: boolean;
  password?: string;
  redirect_url: string;
  requires_first_time_reset: boolean;
  requires_login: boolean;
  sender_email: string;
  sender_name: string;
  sent_at: string;
  sent_at_formatted: string;
  signed_at?: string;
  signed_at_formatted?: string;
  signup_message_raw_html: string;
  token: string;
}

export interface InvitationState {
  invitation?: Invitation;
}

export function createInitialState(): InvitationState {
  return {
    invitation: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invitation', resettable: true })
export class InvitationStore extends Store<InvitationState> {
  constructor() {
    super(createInitialState());
  }
}
