import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends BaseService {
  request: any;
  currentRequestForm: any;

  constructor(private http: HttpClient) {
    super();
  }

  getInvestmentContacts(vehicleId: string, callbacks?: any): void {
    this.http.get(`${this.BASE_URL}/vehicles/${vehicleId}/investment_contacts`).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  getVehicles(organizationId: ID, callbacks?: any): void {
    this.http.get(`${this.BASE_URL}/v1/organizations/${organizationId}/vehicles`).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }
}
