import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

export enum MessageBoxType {
  Alert = 'alert',
  Complete = 'complete',
  Info = 'info',
  Warning = 'warning'
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-message-box',
  styleUrls: ['./message-box.component.scss'],
  templateUrl: './message-box.component.html'
})
export class MessageBoxComponent implements OnInit {
  @Input() type: MessageBoxType;
  @Input() messages: string[];

  constructor() {}

  @HostBinding('class') get classes(): string {
    return this.type;
  }

  ngOnInit() {}
}
