import { extractLeadingNumber } from '@helpers/string';
import { formatCurrency } from '@angular/common';

export function getTotalValue(collection: any[], attribute: string): number {
  return collection.reduce<number>((total: number, row: any) => {
    let val = 0;
    let rowVal = row[attribute];

    if (rowVal) {
      if (typeof rowVal === 'string') {
        val = parseFloat(rowVal.replace(/[\$,]/g, ''));
      } else {
        val = rowVal;
      }
    }

    return total + val;
  }, 0);
}

export function getTotalValueAsNumber(collection: any[], attribute: string): number {
  return collection.reduce<number>((total: number, row: any) => {
    let val = 0;
    let rowVal: string = row[attribute];

    if (rowVal) {
      if (typeof rowVal === 'string') {
        val = parseFloat(rowVal);
      } else {
        val = rowVal;
      }
    }

    return total + val;
  }, 0);
}

export function getTotalValueAsCurrency(collection: any[], attribute: string): string {
  let totalValue = getTotalValue(collection, attribute);
  return formatCurrency(totalValue, 'en-US', '$');
}

export function sortByLeadingNumber(stringA: string, stringB: string): number {
  if (!stringA || !stringB) return 0;
  let numberA = extractLeadingNumber(stringA);
  let numberB = extractLeadingNumber(stringB);
  if (numberA && numberB) return numberA - numberB;
  return stringA.localeCompare(stringB);
}
