import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FunctionPipeModule } from '@pipe/function';
import { NoneModule } from '@pipe/none';

import { BlankLayoutComponent } from './blank-layout.component';
import { BlankLayoutContainerComponent } from './blank-layout-container';

@NgModule({
  declarations: [BlankLayoutComponent, BlankLayoutContainerComponent],
  exports: [BlankLayoutComponent, BlankLayoutContainerComponent],
  imports: [CommonModule, FunctionPipeModule, InlineSVGModule, NoneModule, RouterModule]
})
export class BlankLayoutModule {}
