import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TERMS_OF_USE_URL } from '@global';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-signature-wizard-dialog',
  styleUrls: ['signature-wizard-dialog.component.scss'],
  templateUrl: 'signature-wizard-dialog.component.html'
})
export class SignatureWizardDialogComponent {
  signatureText: string;

  readonly TERMS_OF_USE_URL = TERMS_OF_USE_URL;

  @HostListener('window:keyup.esc')
  onKeyUp(): void {
    this.dialogRef.close();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SignatureWizardDialogComponent>
  ) {
    this.signatureText = !data.signatureText ? '' : data.signatureText;
  }

  get isSignatureInvalid(): boolean {
    return this.signatureText.trim() === '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
