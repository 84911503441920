import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'function'
})
export class FunctionPipe implements PipeTransform {
  transform(value: any, fn: (...args: any) => any, ...params: any[]): any {
    return fn(value, params);
  }
}
