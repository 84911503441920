import { Pipe, PipeTransform } from '@angular/core';

import { ColumnLike, ColumnType, IconBadgeColumn } from '../../collection-table.model';

@Pipe({
  name: 'asIconBadgeColumn'
})
export class AsIconBadgeColumnPipe implements PipeTransform {
  transform(column: ColumnLike): IconBadgeColumn | false {
    if (column.type === ColumnType.IconBadge) return column as IconBadgeColumn;
    return false;
  }
}
