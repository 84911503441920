import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { InvitationState, InvitationStore } from './invitation.store';

@Injectable({ providedIn: 'root' })
export class InvitationQuery extends Query<InvitationState> {
  selectInvitation$ = this.select((state) => state.invitation);

  constructor(protected store: InvitationStore) {
    super(store);
  }
}
