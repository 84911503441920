import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ID } from '@state/base';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Document, DocumentDownloadURL, DocumentUploadPreview } from './document.model';
import { DocumentRepository } from './document.repository';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(private http: HttpClient, private documentRepository: DocumentRepository) {}

  archive$(documentId: ID, providerId?: ID): Observable<null> {
    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.delete<null>(`${environment.apiUrl}/v1/documents/${documentId}`, options);
  }

  downloadViaToken(documentId: ID | string, providerId?: ID): Subscription {
    return this.getDownloadToken$(documentId, providerId).subscribe({
      next: (data) => {
        window.location.href = data.download_token;
      }
    });
  }

  get$(documentId: ID, providerId?: ID): Observable<Document> {
    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.get<Document>(`${environment.apiUrl}/v1/documents/${documentId}`, options);
  }

  getAndStore$(documentId: ID, providerId?: ID): Observable<Document> {
    return this.get$(documentId, providerId).pipe(
      tap({
        next: (document) => {
          this.documentRepository.setDocument(document);
        }
      })
    );
  }

  getDownloadToken$(documentId: ID, providerId?: ID): Observable<DocumentDownloadURL> {
    let url = `${environment.apiUrl}/documents/${documentId}/download_url`;

    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.get<DocumentDownloadURL>(url, options);
  }

  getPreview$(document_id: ID, parseCurrency: boolean = false): Observable<DocumentUploadPreview> {
    let params: HttpParams = new HttpParams({ fromObject: { parse_currency: parseCurrency } });
    let url = `${environment.apiUrl}/v1/documents/${document_id}/preview_upload`;
    return this.http.get<DocumentUploadPreview>(url, { params });
  }

  reset(): void {
    this.documentRepository.reset();
  }
}
