import { ElementObjectType, Form } from '@state/form';

export function removeElementsWithPageObjectsFromForm(
  form: Form,
  removeType: ElementObjectType = ElementObjectType.CounterSignable
): Form {
  if (!form || !form.document) return form;

  for (let section of form.sections) {
    let filteredElements = [];

    for (let element of section.elements) {
      if (removeType === ElementObjectType.CounterSignable) {
        if (!element.element_object.page_object_id) {
          filteredElements.push(element);
        } else if (!form.document.page_objects[element.element_object.page_object_id].counter_signable) {
          filteredElements.push(element);
        }
      } else if (removeType === ElementObjectType.Primary) {
        if (form.document.page_objects[element.element_object.page_object_id].counter_signable) {
          filteredElements.push(element);
        }
      }
    }

    section.elements = filteredElements;
  }

  return form;
}
