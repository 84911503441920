import { Injectable } from '@angular/core';

import { AuthLayoutRepository } from './auth-layout.repository';

@Injectable({ providedIn: 'root' })
export class AuthLayoutService {
  constructor(private authLayoutRepository: AuthLayoutRepository) {}

  setMessage(message: string): void {
    this.authLayoutRepository.setMessage(message);
  }
}
