import { Pipe, PipeTransform } from '@angular/core';

import { parseDate } from '@helpers/date';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  transform(value: number | string | Date, format?: string, utc?: boolean): any {
    return parseDate(value, format, utc);
  }
}
