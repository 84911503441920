import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-authorization-layout',
  styleUrls: ['./authorization-layout.component.scss'],
  templateUrl: './authorization-layout.component.html'
})
export class AuthorizationLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
