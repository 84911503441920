export const countries: { key: string; label: string }[] = [
  { key: 'US', label: 'United States' },
  { key: 'AW', label: 'Aruba' },
  { key: 'AF', label: 'Afghanistan' },
  { key: 'AO', label: 'Angola' },
  { key: 'AI', label: 'Anguilla' },
  { key: 'AX', label: 'Åland Islands' },
  { key: 'AL', label: 'Albania' },
  { key: 'AD', label: 'Andorra' },
  { key: 'AE', label: 'United Arab Emirates' },
  { key: 'AR', label: 'Argentina' },
  { key: 'AM', label: 'Armenia' },
  { key: 'AS', label: 'American Samoa' },
  { key: 'AQ', label: 'Antarctica' },
  { key: 'TF', label: 'French Southern and Antarctic Lands' },
  { key: 'AG', label: 'Antigua and Barbuda' },
  { key: 'AU', label: 'Australia' },
  { key: 'AT', label: 'Austria' },
  { key: 'AZ', label: 'Azerbaijan' },
  { key: 'BI', label: 'Burundi' },
  { key: 'BE', label: 'Belgium' },
  { key: 'BJ', label: 'Benin' },
  { key: 'BF', label: 'Burkina Faso' },
  { key: 'BD', label: 'Bangladesh' },
  { key: 'BG', label: 'Bulgaria' },
  { key: 'BH', label: 'Bahrain' },
  { key: 'BS', label: 'Bahamas' },
  { key: 'BA', label: 'Bosnia and Herzegovina' },
  { key: 'BL', label: 'Saint Barthélemy' },
  { key: 'BY', label: 'Belarus' },
  { key: 'BZ', label: 'Belize' },
  { key: 'BM', label: 'Bermuda' },
  { key: 'BO', label: 'Bolivia' },
  { key: 'BR', label: 'Brazil' },
  { key: 'BB', label: 'Barbados' },
  { key: 'BN', label: 'Brunei' },
  { key: 'BT', label: 'Bhutan' },
  { key: 'BV', label: 'Bouvet Island' },
  { key: 'BW', label: 'Botswana' },
  { key: 'CF', label: 'Central African Republic' },
  { key: 'CA', label: 'Canada' },
  { key: 'CC', label: 'Cocos (Keeling) Islands' },
  { key: 'CH', label: 'Switzerland' },
  { key: 'CL', label: 'Chile' },
  { key: 'CN', label: 'China' },
  { key: 'CI', label: 'Ivory Coast' },
  { key: 'CM', label: 'Cameroon' },
  { key: 'CD', label: 'DR Congo' },
  { key: 'CG', label: 'Republic of the Congo' },
  { key: 'CK', label: 'Cook Islands' },
  { key: 'CO', label: 'Colombia' },
  { key: 'KM', label: 'Comoros' },
  { key: 'CV', label: 'Cape Verde' },
  { key: 'CR', label: 'Costa Rica' },
  { key: 'CU', label: 'Cuba' },
  { key: 'CW', label: 'Curaçao' },
  { key: 'CX', label: 'Christmas Island' },
  { key: 'KY', label: 'Cayman Islands' },
  { key: 'CY', label: 'Cyprus' },
  { key: 'CZ', label: 'Czechia' },
  { key: 'DE', label: 'Germany' },
  { key: 'DJ', label: 'Djibouti' },
  { key: 'DM', label: 'Dominica' },
  { key: 'DK', label: 'Denmark' },
  { key: 'DO', label: 'Dominican Republic' },
  { key: 'DZ', label: 'Algeria' },
  { key: 'EC', label: 'Ecuador' },
  { key: 'EG', label: 'Egypt' },
  { key: 'ER', label: 'Eritrea' },
  { key: 'EH', label: 'Western Sahara' },
  { key: 'ES', label: 'Spain' },
  { key: 'EE', label: 'Estonia' },
  { key: 'ET', label: 'Ethiopia' },
  { key: 'FI', label: 'Finland' },
  { key: 'FJ', label: 'Fiji' },
  { key: 'FK', label: 'Falkland Islands' },
  { key: 'FR', label: 'France' },
  { key: 'FO', label: 'Faroe Islands' },
  { key: 'FM', label: 'Micronesia' },
  { key: 'GA', label: 'Gabon' },
  { key: 'GB', label: 'United Kingdom' },
  { key: 'GE', label: 'Georgia' },
  { key: 'GG', label: 'Guernsey' },
  { key: 'GH', label: 'Ghana' },
  { key: 'GI', label: 'Gibraltar' },
  { key: 'GN', label: 'Guinea' },
  { key: 'GP', label: 'Guadeloupe' },
  { key: 'GM', label: 'Gambia' },
  { key: 'GW', label: 'Guinea-Bissau' },
  { key: 'GQ', label: 'Equatorial Guinea' },
  { key: 'GR', label: 'Greece' },
  { key: 'GD', label: 'Grenada' },
  { key: 'GL', label: 'Greenland' },
  { key: 'GT', label: 'Guatemala' },
  { key: 'GF', label: 'French Guiana' },
  { key: 'GU', label: 'Guam' },
  { key: 'GY', label: 'Guyana' },
  { key: 'HK', label: 'Hong Kong' },
  { key: 'HM', label: 'Heard Island and McDonald Islands' },
  { key: 'HN', label: 'Honduras' },
  { key: 'HR', label: 'Croatia' },
  { key: 'HT', label: 'Haiti' },
  { key: 'HU', label: 'Hungary' },
  { key: 'ID', label: 'Indonesia' },
  { key: 'IM', label: 'Isle of Man' },
  { key: 'IN', label: 'India' },
  { key: 'IO', label: 'British Indian Ocean Territory' },
  { key: 'IE', label: 'Ireland' },
  { key: 'IR', label: 'Iran' },
  { key: 'IQ', label: 'Iraq' },
  { key: 'IS', label: 'Iceland' },
  { key: 'IL', label: 'Israel' },
  { key: 'IT', label: 'Italy' },
  { key: 'JM', label: 'Jamaica' },
  { key: 'JE', label: 'Jersey' },
  { key: 'JO', label: 'Jordan' },
  { key: 'JP', label: 'Japan' },
  { key: 'KZ', label: 'Kazakhstan' },
  { key: 'KE', label: 'Kenya' },
  { key: 'KG', label: 'Kyrgyzstan' },
  { key: 'KH', label: 'Cambodia' },
  { key: 'KI', label: 'Kiribati' },
  { key: 'KN', label: 'Saint Kitts and Nevis' },
  { key: 'KR', label: 'South Korea' },
  { key: 'XK', label: 'Kosovo' },
  { key: 'KW', label: 'Kuwait' },
  { key: 'LA', label: 'Laos' },
  { key: 'LB', label: 'Lebanon' },
  { key: 'LR', label: 'Liberia' },
  { key: 'LY', label: 'Libya' },
  { key: 'LC', label: 'Saint Lucia' },
  { key: 'LI', label: 'Liechtenstein' },
  { key: 'LK', label: 'Sri Lanka' },
  { key: 'LS', label: 'Lesotho' },
  { key: 'LT', label: 'Lithuania' },
  { key: 'LU', label: 'Luxembourg' },
  { key: 'LV', label: 'Latvia' },
  { key: 'MO', label: 'Macau' },
  { key: 'MF', label: 'Saint Martin' },
  { key: 'MA', label: 'Morocco' },
  { key: 'MC', label: 'Monaco' },
  { key: 'MD', label: 'Moldova' },
  { key: 'MG', label: 'Madagascar' },
  { key: 'MV', label: 'Maldives' },
  { key: 'MX', label: 'Mexico' },
  { key: 'MH', label: 'Marshall Islands' },
  { key: 'MK', label: 'Macedonia' },
  { key: 'ML', label: 'Mali' },
  { key: 'MT', label: 'Malta' },
  { key: 'MM', label: 'Myanmar' },
  { key: 'ME', label: 'Montenegro' },
  { key: 'MN', label: 'Mongolia' },
  { key: 'MP', label: 'Northern Mariana Islands' },
  { key: 'MZ', label: 'Mozambique' },
  { key: 'MR', label: 'Mauritania' },
  { key: 'MS', label: 'Montserrat' },
  { key: 'MQ', label: 'Martinique' },
  { key: 'MU', label: 'Mauritius' },
  { key: 'MW', label: 'Malawi' },
  { key: 'MY', label: 'Malaysia' },
  { key: 'YT', label: 'Mayotte' },
  { key: 'NA', label: 'Namibia' },
  { key: 'NC', label: 'New Caledonia' },
  { key: 'NE', label: 'Niger' },
  { key: 'NF', label: 'Norfolk Island' },
  { key: 'NG', label: 'Nigeria' },
  { key: 'NI', label: 'Nicaragua' },
  { key: 'NU', label: 'Niue' },
  { key: 'NL', label: 'Netherlands' },
  { key: 'NO', label: 'Norway' },
  { key: 'NP', label: 'Nepal' },
  { key: 'NR', label: 'Nauru' },
  { key: 'NZ', label: 'New Zealand' },
  { key: 'OM', label: 'Oman' },
  { key: 'PK', label: 'Pakistan' },
  { key: 'PA', label: 'Panama' },
  { key: 'PN', label: 'Pitcairn Islands' },
  { key: 'PE', label: 'Peru' },
  { key: 'PH', label: 'Philippines' },
  { key: 'PW', label: 'Palau' },
  { key: 'PG', label: 'Papua New Guinea' },
  { key: 'PL', label: 'Poland' },
  { key: 'PR', label: 'Puerto Rico' },
  { key: 'KP', label: 'North Korea' },
  { key: 'PT', label: 'Portugal' },
  { key: 'PY', label: 'Paraguay' },
  { key: 'PS', label: 'Palestine' },
  { key: 'PF', label: 'French Polynesia' },
  { key: 'QA', label: 'Qatar' },
  { key: 'RE', label: 'Réunion' },
  { key: 'RO', label: 'Romania' },
  { key: 'RU', label: 'Russia' },
  { key: 'RW', label: 'Rwanda' },
  { key: 'SA', label: 'Saudi Arabia' },
  { key: 'SD', label: 'Sudan' },
  { key: 'SN', label: 'Senegal' },
  { key: 'SG', label: 'Singapore' },
  { key: 'GS', label: 'South Georgia' },
  { key: 'SJ', label: 'Svalbard and Jan Mayen' },
  { key: 'SB', label: 'Solomon Islands' },
  { key: 'SL', label: 'Sierra Leone' },
  { key: 'SV', label: 'El Salvador' },
  { key: 'SM', label: 'San Marino' },
  { key: 'SO', label: 'Somalia' },
  { key: 'PM', label: 'Saint Pierre and Miquelon' },
  { key: 'RS', label: 'Serbia' },
  { key: 'SS', label: 'South Sudan' },
  { key: 'ST', label: 'São Tomé and Príncipe' },
  { key: 'SR', label: 'Suriname' },
  { key: 'SK', label: 'Slovakia' },
  { key: 'SI', label: 'Slovenia' },
  { key: 'SE', label: 'Sweden' },
  { key: 'SZ', label: 'Swaziland' },
  { key: 'SX', label: 'Sint Maarten' },
  { key: 'SC', label: 'Seychelles' },
  { key: 'SY', label: 'Syria' },
  { key: 'TC', label: 'Turks and Caicos Islands' },
  { key: 'TD', label: 'Chad' },
  { key: 'TG', label: 'Togo' },
  { key: 'TH', label: 'Thailand' },
  { key: 'TJ', label: 'Tajikistan' },
  { key: 'TK', label: 'Tokelau' },
  { key: 'TM', label: 'Turkmenistan' },
  { key: 'TL', label: 'Timor-Leste' },
  { key: 'TO', label: 'Tonga' },
  { key: 'TT', label: 'Trinidad and Tobago' },
  { key: 'TN', label: 'Tunisia' },
  { key: 'TR', label: 'Turkey' },
  { key: 'TV', label: 'Tuvalu' },
  { key: 'TW', label: 'Taiwan' },
  { key: 'TZ', label: 'Tanzania' },
  { key: 'UG', label: 'Uganda' },
  { key: 'UA', label: 'Ukraine' },
  { key: 'UM', label: 'United States Minor Outlying Islands' },
  { key: 'UY', label: 'Uruguay' },
  { key: 'UZ', label: 'Uzbekistan' },
  { key: 'VA', label: 'Vatican City' },
  { key: 'VC', label: 'Saint Vincent and the Grenadines' },
  { key: 'VE', label: 'Venezuela' },
  { key: 'VG', label: 'British Virgin Islands' },
  { key: 'VI', label: 'United States Virgin Islands' },
  { key: 'VN', label: 'Vietnam' },
  { key: 'VU', label: 'Vanuatu' },
  { key: 'WF', label: 'Wallis and Futuna' },
  { key: 'WS', label: 'Samoa' },
  { key: 'YE', label: 'Yemen' },
  { key: 'ZA', label: 'South Africa' },
  { key: 'ZM', label: 'Zambia' },
  { key: 'ZW', label: 'Zimbabwe' }
];

export const us_states: { key: string; label: string }[] = [
  { key: 'al', label: 'Alabama' },
  { key: 'ak', label: 'Alaska' },
  { key: 'as', label: 'American Samoa' },
  { key: 'az', label: 'Arizona' },
  { key: 'ar', label: 'Arkansas' },
  { key: 'ca', label: 'California' },
  { key: 'co', label: 'Colorado' },
  { key: 'ct', label: 'Connecticut' },
  { key: 'dc', label: 'District of Columbia' },
  { key: 'de', label: 'Delaware' },
  { key: 'fl', label: 'Florida' },
  { key: 'ga', label: 'Georgia' },
  { key: 'gu', label: 'Guam' },
  { key: 'hi', label: 'Hawaii' },
  { key: 'id', label: 'Idaho' },
  { key: 'il', label: 'Illinois' },
  { key: 'in', label: 'Indiana' },
  { key: 'ia', label: 'Iowa' },
  { key: 'ks', label: 'Kansas' },
  { key: 'ky', label: 'Kentucky' },
  { key: 'la', label: 'Louisiana' },
  { key: 'me', label: 'Maine' },
  { key: 'md', label: 'Maryland' },
  { key: 'ma', label: 'Massachusetts' },
  { key: 'mi', label: 'Michigan' },
  { key: 'mn', label: 'Minnesota' },
  { key: 'mp', label: 'Northern Mariana Islands' },
  { key: 'ms', label: 'Mississippi' },
  { key: 'mo', label: 'Missouri' },
  { key: 'mt', label: 'Montana' },
  { key: 'ne', label: 'Nebraska' },
  { key: 'nv', label: 'Nevada' },
  { key: 'nh', label: 'New Hampshire' },
  { key: 'nj', label: 'New Jersey' },
  { key: 'nm', label: 'New Mexico' },
  { key: 'ny', label: 'New York' },
  { key: 'nc', label: 'North Carolina' },
  { key: 'nd', label: 'North Dakota' },
  { key: 'oh', label: 'Ohio' },
  { key: 'ok', label: 'Oklahoma' },
  { key: 'or', label: 'Oregon' },
  { key: 'pa', label: 'Pennsylvania' },
  { key: 'pr', label: 'Puerto Rico' },
  { key: 'ri', label: 'Rhode Island' },
  { key: 'sc', label: 'South Carolina' },
  { key: 'sd', label: 'South Dakota' },
  { key: 'tn', label: 'Tennessee' },
  { key: 'tx', label: 'Texas' },
  { key: 'ut', label: 'Utah' },
  { key: 'vi', label: 'United States Virgin Islands' },
  { key: 'vt', label: 'Vermont' },
  { key: 'va', label: 'Virginia' },
  { key: 'wa', label: 'Washington' },
  { key: 'wv', label: 'West Virginia' },
  { key: 'wi', label: 'Wisconsin' },
  { key: 'wy', label: 'Wyoming' }
];
