import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { FormSection } from '@state/form';
import { toString } from '@helpers/string';

import { TaskState, TaskStore } from './task.store';

function visibleSections(state: TaskState) {
  let { formValues, task } = state;
  return task?.form?.sections.filter((section: FormSection) => {
    let { visibility_key, visibility_values } = section;

    if (visibility_key) {
      if (visibility_values.length) {
        // This is a special value for visibility
        let nullValueIndex = visibility_values.indexOf('**null**');
        if (nullValueIndex > -1) visibility_values[nullValueIndex] = '';

        let visibilityKey = toString(formValues[visibility_key]);
        return visibility_values.indexOf(visibilityKey) > -1;
      } else {
        return !formValues[visibility_key];
      }
    } else {
      return true;
    }
  });
}

@Injectable({ providedIn: 'root' })
export class TaskQuery extends Query<TaskState> {
  form$ = this.select('form');
  task$ = this.select('task');
  vehicle$ = this.select('vehicle');
  visibleSections$ = this.select(visibleSections).pipe(filter((sections) => !!sections));

  constructor(protected store: TaskStore) {
    super(store);
  }
}
