import { Pipe, PipeTransform } from '@angular/core';

import { MetaColumn, ColumnLike, ColumnType } from '../../collection-table.model';

@Pipe({
  name: 'asMetaColumn'
})
export class AsMetaColumnPipe implements PipeTransform {
  transform(column: ColumnLike): MetaColumn | false {
    if (column.type === ColumnType.Meta) return column as MetaColumn;
    return false;
  }
}
