/*
  Investment Service
*/

import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InvestmentService extends BaseService {
  investment: any;

  constructor(private http: HttpClient) {
    super();

    // this.BASE_URL += '/invitations/';
  }

  getInvestmentForCounterSign(investmentId: ID, callbacks?: any): void {
    let url = `${this.BASE_URL}/investments/${investmentId}`;

    this.isLoading = true;
    this.http.get(url).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.investment = data;
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  counterSign(investmentId: ID, formId: ID, formFieldValues: any, callbacks?: any): void {
    let url = `${this.BASE_URL}/investments/${investmentId}/counter_sign`;
    let params = {
      form_id: formId,
      form_values: formFieldValues
    };

    this.isLoading = true;
    this.http.post(url, params).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.investment = data;
        this.isLoading = false;
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  sendReminder(id: ID, callbacks?: any): void {
    let url = `${environment.apiUrl}/investments/${id}/send_reminder`;
    this.http.post(url, {}).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.investment = data;
        this.isLoading = false;
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  /*
    This needs to be refactored. Should not live this investmentService. Entire class
    needs to be deprecated. Only changing the endpoint and method as a hotfix.

    - Adrian Ortiz
  */
  revokeInvitation(id: string, message?: string, callbacks?: any): void {
    let url = `${environment.apiUrl}/v1/investments/${id}/invitation_declines`;
    this.http.put(url, { message }).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.investment = data;
        this.isLoading = false;
        this.applyCallback('success', callbacks, data);
      }
    });
  }

  investmentDisable(id: ID, message?: string, callbacks?: any): void {
    let url = `${environment.apiUrl}/v1/investments/${id}/investment_disables`;
    this.http.put(url, { message }).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.investment = data;
        this.isLoading = false;
        this.applyCallback('success', callbacks, data);
      }
    });
  }
}
